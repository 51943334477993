.MuiStepper-root {
  padding: 13px !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #0079bf !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #5096be !important;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 12px !important;
}
.MuiStepLabel-label.MuiStepLabel-active {
  font-weight: bold !important;
  font-family: sans-serif;
  font-size: 13px;
}

.MuiStepLabel-label {
  font-family: sans-serif;
  font-size: 13px;
}
.MuiStepLabel-label.MuiStepLabel-completed {
  font-weight: bold !important;
  font-family: sans-serif;
  font-size: 13px;
}
.MuiSvgIcon-root {
  font-size: 20px !important;
}
.MuiStepConnector-alternativeLabel {
  top: 15px !important;
}

.staffpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 64%;
  height: 80vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.staffpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red;   77vh*/
  height: 67vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.feature {
  position: relative;
  width: 65px;
  height: 30px;
  margin: 5px;
  float: left;
  border: 1px solid var(--MainColor);
  border-radius: 5px;
  box-sizing: border-box;
}

.feature div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
}

.feature input {
  position: absolute;
  top: 0;
  left: 0;
  width: 65px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked ~ div {
  background-color: var(--MainColor);
  color: #ffffff;
}

.do-flex {
  display: flex;
}

.checkbox-close {
  text-align: left;
  /* padding-left: 25px; */
  /* margin-block-start: auto; */
}

.sliderpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  height: 45vh;
}

.sliderpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 44%;
  /* height: 80vh; */
  top: 18vh;
  left: 25%;
  position: absolute;
}

@media screen and (max-width: 900px) {
  .staffpopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .staffpopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}
