.bankpopup-inner {
  background-color: white;
  max-width: 950px;
  width: 50%;
  height: 71vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

@media screen and (max-width: 900px) {
  .bankpopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .bankpopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}