.voucher-input {
  background-color: transparent !important;
}
.voucherField {
  background: #fff !important;
}
.jrYYEB > .wrapper:hover,
.jrYYEB > .wrapper:active {
  box-shadow: none !important;
}
.jrYYEB > .wrapper {
  background-color: transparent !important;
}

.sc-himrzO > ul {
  background-color: #fafdfb;
  padding-bottom: 0 !important;
}
.sc-himrzO > div.line {
  border-top: 1px solid transparent !important;
}
.jrYYEB > .wrapper:focus-within {
  box-shadow: none !important;
}

.equal {
  margin: 0;
  font-size: 24px;
  margin-top: 1.2rem;
  color: #555;
}

.ledger-side,
.base-side {
  width: 47%;
}

.ledger-side span,
.base-side span {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  border-right: 1px solid #d9d9d9;
  padding-right: 0.5rem;
}

.equal-side {
  width: 6%;
}

.voucherCell .form-control:disabled {
  background-color: transparent !important;
}

.voucher-table .tablecell {
  padding: 0 0.5rem !important;
  vertical-align: middle;
}
.voucher-table .tablecell .field {
  position: relative;
}
.voucher-table .tablecell .field .icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #555;
}
.voucher-table .tablecell .field .icon .infoBox {
  position: absolute;
  top: 15px;
  left: 15px;
  min-width: max-content;
  min-height: 200px;
  padding: 25px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
  z-index: 1020;
}

.voucher-table tfoot .tablecell {
  padding: 8px 12px !important;
}
