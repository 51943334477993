.subdepartmentpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50%;
  height: 50vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.subdepartmentpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  /* height: 45vh;
  overflow-y: scroll;
  padding-bottom: 3rem; */
}
.designationpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50%;
  height: 56vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.designationpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 45vh;
  overflow-y: scroll;
  padding-bottom: 3rem;
}

@media screen and (max-width: 900px) {
  .subdepartmentpopup-inner,
  .designationpopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .subdepartmentpopup-inner,
  .designationpopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}
