.layout-wrapper {
  position: relative;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
.main {
  transition: 0.1s;
  /* border: 1px solid red; */
  margin-left: 250px;
  /* top: 0;
  position: relative;
  z-index: 4; */
}
.pushMain {
  margin-left: 0px !important;
}

.head-top {
  position: sticky;
  top: -1px;
  z-index: 10000;
}

@media screen and (max-width: 795px) {
  .nav-icons {
    display: none;
  }
  .main {
    transition: 0.1s;
    margin-left: 0px;
  }
  .pushMain {
    margin-left: 0px !important;
  }
}

.page-header {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  padding-left: 0.7rem !important;
}

.page-title {
  /* border: 1px solid red; */
  color: #000;
  font-size: 23px;
}
.page-date {
  /* border: 1px solid red; */
  margin-right: 10px;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.title-hr {
  padding: 1px;
  margin-left: 10px;
  margin-top: 10px;
  width: 98%;
}

@media screen and (max-width: 650px) {
  .page-date {
    display: none !important;
  }
}

/* .btn {
  font-weight: 700;
} */
.form-control,
.btn,
.form-select {
  border-radius: 3px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  /* padding: 0.6em; */
}
/* .btn {
  padding: 0.38em 0.6rem;
} */
.form-control:focus,
.btn:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: "var(--button-color)";
  outline: none;
}
.form-select:focus {
  border-radius: none !important;
}

.row > * {
  padding-right: 0.2rem;
  padding-left: 0.2rem;
}
.export-btn button {
  padding: 0.3rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.form-padding {
  padding: 0 0.7rem;
}
.form-in-padding {
  padding: 0 1.2rem;
}

.form-control:disabled
/* .form-control[readonly]  */ {
  background-color: #e9ecef !important;
  opacity: 1;
}

.form-select:disabled {
  background-color: #e9ecef !important;
}

label {
  color: #555 !important;
  font-size: 12px !important;
  margin-bottom: 0.25rem;
}
.form-control,
.form-select {
  background-color: #efefef7d !important;
  border: none;
}
.form-control:focus {
  background-color: #efefef7d;
}

.rdt_TableCol {
  /* border: 1px solid red; */
  padding: 10px !important;
  font-size: 14px !important;
  font-weight: bolder;
  /* justify-content: center; */
  /* background-color: #fafbfc !important; */
  /* color: #212529 !important; */
}

.rdt_TableCell {
  /* border: 1px solid black; */
  font-size: 13px !important;
  padding: 0.1rem 1rem !important;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  /* justify-content: center; */
  /* color: #212529 !important; */
}

/* .sc-dkzDqf.faVNZf.rdt_TableHeadRow {
  background-color: #fafbfc !important;
} */

.rdt_TableHeadRow {
  background-color: #004aad !important;
  color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.rdt_TableBody {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

/* .rdt_Table {
  border: 1px solid rgba(0, 0, 0, 0.12);
} */

.sec-dataTable {
  /* border: 1px solid #e1e2e3; */
  /* padding: 10px 15px; */
  padding: 0 1rem;
  /* height: 80vh; */
  height: auto;
  min-height: calc(100vh - 12rem);
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  z-index: 200;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border-radius: 0.375rem;
  background: #fff;
}

.sec-dataTable::-webkit-scrollbar {
  display: none;
}

.sec-dataTable {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.errBox {
  border: 1px solid red;
}

/* toaster */
/* .toast {
  background-color: red;
} */

/* cal-picker */
input._1oYOS.form-control.form-control-sm.pt-0.pb-0 {
  background-color: white;
}
._2xcMq {
  top: -2px !important;
  max-width: 300px;
  width: 100%;
  position: absolute;
  background-color: #fff;
  /* z-index: 1000 !important; */
  color: #000;
  box-shadow: none !important;
  font-size: 12px !important;
  line-height: 1 !important;
  /* border: 1px solid #e1e2e3 !important; */
  display: flex !important;
  justify-content: center !important;
}

p._3fmCl._1Pw8c._bBYU7 {
  display: none !important;
}

p._3fmCl._1ydFT._bBYU7 {
  display: none !important;
}

select._2aNF9 {
  font-size: 13px;
  margin-left: 3px;
  border: none !important;
}

._1Op28._1mUuW {
  display: flex !important;
  justify-content: center;
}

span._249_g._3zVi3._FsLy_._1ImcB {
  border-radius: inherit;
}

/* search-field */
.searchField {
  /* border: 1px solid red; */
  height: 14px !important;
  font-size: 0.875rem !important;
  padding-right: 2rem;
}

.form-select-sm {
  padding-top: 0.344rem !important;
  padding-bottom: 0.344rem !important;
}

.dlwHJS,
.iwWoeI,
.jocBkY,
.huvJhe,
.fPfnIu {
  background-color: #f8f8ff !important;
}

.sc-dmRaPn {
  border-radius: 0 !important;
}

.sc-dIouRR {
  justify-content: space-between !important;
}

.search-icon {
  position: absolute;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
  font-size: 20px;
}

.uk-search-default {
  width: 200px;
}

.dark-gray {
  color: #555;
  font-size: 12px !important;
}

.uk-table-striped tbody tr:nth-of-type(odd),
.uk-table-striped > tr:nth-of-type(odd) {
  background: #f8f8ff !important;
}

.printPop-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  display: none;
}

.printPop-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50vw;
  height: 80vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.uk-table .sticky-head {
  position: sticky;
  top: 90px;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
}

.printBody thead tr {
  background-color: #eaeaea !important;
  color: #000;
}
.printBody .printTable {
  margin-top: 6rem;
}
.printBody .printHeader {
  background: #fff;
  width: 100%;
  position: fixed;
  top: 50px;
  /* margin-left: -1px; */
  padding-top: 0.5rem;
  left: 0;
}
.printBody thead tr td {
  padding: 10px !important;
  font-size: 14px !important;
  /* border-left: 1px solid rgba(0, 0, 0, 0.12); */
}
.printBody tbody tr td:first-child {
  min-width: 70px;
}
.printBody tbody tr td {
  padding: 0.7rem 1rem !important;
  min-width: 150px;
  font-size: 13px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.printBody tbody tr td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.classatten-wrapper {
  margin-top: 1rem;
}

@media screen and (max-width: 900px) {
  .printPop-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .printPop-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}

/* ---search autocomplete css */
.sc-ciZhAO {
  height: 30px !important;
}
.sc-ciZhAO .wrapper {
  border: none !important;
  border-radius: 0 !important;
}
/* .sc-ciZhAO > .wrapper:hover {
  box-shadow: none !important;
} */

.sc-ciZhAO .search-icon,
.sc-ciZhAO .clear-icon {
  display: none !important;
}
.sc-ciZhAO .sc-cCsOjp {
  min-height: 0 !important;
}
.sc-ciZhAO input {
  padding: 0.25rem 0.5rem !important;
  background-color: transparent !important;
  border-radius: 3px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
}
.childRow .sc-ciZhAO input {
  padding: 0.45rem 0.5rem !important;
}

/* ---searchable dropdown css */

.css-1s2u09g-control {
  border: none !important;
  background: transparent !important;
  min-height: 0 !important;
  transition: none !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
  transition: none !important;
}
.css-6j8wv5-Input,
.css-319lph-ValueContainer,
.css-1hb7zxy-IndicatorsContainer,
.css-tlfecz-indicatorContainer {
  padding: 0 !important;
  transition: none !important;
}

.css-1s2u09g-control {
  min-height: 0 !important;
  border: none;
  box-shadow: none !important;
  background: transparent !important;
  padding: 0 !important;
  border-color: transparent !important;
  transition: none !important;
}

.css-tlfecz-indicatorContainer {
  padding: 0 !important;
  transition: none !important;
}

.css-1pahdxg-control:hover,
.css-1pahdxg-control:focus {
  border-color: transparent !important;
  min-height: 0 !important;
  box-shadow: none !important;
}

.Toastify__close-button {
  align-self: center;
}
