tr:nth-of-type(odd) {
  background-color: #f8f8ff;
}
.tablecell {
  padding: 0.5rem 1rem !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tablecell:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.tableHead {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 1rem !important;
  font-weight: bold;
}

.attendace-table {
  overflow: auto;
  height: calc(100vh - 18rem);
}
.attendace-table .head-top {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.attendace-table .head-bottom {
  position: sticky;
  top: 38px;
}

.attendace-table::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.attendace-table:hover::-webkit-scrollbar {
  display: block !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.attendace-table::-webkit-scrollbar-track {
  background-color: transparent;
}

.attendace-table::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 1rem;
  width: 40px;
}

._2xcMq {
  width: 170% !important;
}
