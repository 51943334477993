.chgpasspopup-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  display: none;
}

.chgpasspopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 35vw;
  height: 39vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.popUpHeader {
  font-size: 20px;
  /* font-weight: 500; */
  margin: 0;
  /* border: 1px solid red; */
  padding: 10px 10px;
  background-color: var(--button-color);
  color: white;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 50;
  align-items: center;
}

.popUpTitle {
  font-size: 15px;
  padding: 0px 15px;
}

.popUpClose {
  padding-right: 15px;
}

.popUpCloseIcon {
  cursor: pointer;
  opacity: 0.7;
}

.popUpCloseIcon:hover {
  opacity: 1;
}

.chgpassBody {
  position: relative;
  z-index: 10;
  /* border: 1px solid red; */
  /* padding: 3% 1%; */
  color: black;
}

.chgpopup-footer {
  position: absolute;
  bottom: 5px;
  right: 0px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 750px) {
  .chgpasspopup-inner {
    width: 60% !important;
    /* left: 20%; */
  }
}

@media screen and (max-width: 500px) {
  .chgpasspopup-inner {
    width: 80% !important;
    /* left: 10%; */
  }
}
