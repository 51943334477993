.profile-wrapper {
  position: relative;
  z-index: 1;
}
.aboutme-details {
  position: relative;
  /* border: 1px solid red; */
  display: flex;
  height: 70vh;
}
.sec-profile {
  /* border: 1px solid red; */
  /* width: fit-content; */
  /* height: 100%; */
  display: flex;
  position: absolute;
}

.pp-img {
  /* position: absolute; */
  /* border: 1px solid red !important; */
  left: 10px;
  top: 1%;
  width: 50px !important;
  height: 50px !important;
  /* margin-left: 1%; */
  border-radius: 15px;
  /* margin-right: 1%; */
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  overflow: hidden;
}
.sec-detail {
  position: absolute;
  top: -10px;
  left: 70px;
  /* right: 100px; */
  width: 95%;
  /* margin-right: 3%; */
}
.tr-row {
  margin-bottom: 0 !important;
}

.td-row {
  font-size: 13px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.profile-hr {
  margin-top: 5px !important;
  margin-bottom: 0 !important;
  width: 90%;
}

@media screen and (max-width: 500px) {
  .profile-wrapper {
    display: flex;
    flex-direction: column;
  }
  .aboutme-details {
    height: 300px;
  }
  .sec-profile {
    position: absolute;
    justify-content: center;
    width: 60% !important;
    height: 90px !important;
    margin-left: 17.5% !important;
    margin-right: 17.5% !important;
    margin-bottom: 10px;
  }

  .td-row {
    font-size: 12px !important;
  }

  .pp-img {
    width: 80px !important;
    height: 80px !important;
    left: 25% !important;
  }
  .sec-detail {
    position: absolute !important;
    width: 100% !important;
    left: 10px !important;
    top: 85px;
  }
}

:root {
  --MainColor: #356aae;
  --MainTextColor: #656565;
  --textBlack: #232224;
  --Maingray: #f5f8fa;
  --ActiveColor: #eef3f7;
}
.insidePopup {
  text-align: left;
}
.insidePopup {
  background-color: var(--Maingray);
}
.insidePopup ul {
  display: flex;
  padding-left: 0rem !important;
  overflow: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.insidePopup ul::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.insidePopup ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.insidePopup ul li {
  display: inline-block;
  padding: 15px 15px;
  font-weight: 600;
  min-width: max-content;
}
.insidePopup ul li:hover {
  color: var(--textBlack);
  cursor: pointer;
  border-bottom: 2px solid var(--MainColor);
}
.insidePopup ul li.active {
  color: var(--MainColor);
  background: var(--ActiveColor);
  border-bottom: 2px solid var(--MainColor);
}
.insidePopup ul {
  margin-bottom: 6px !important;
}
/* .icon {
  padding-right: 5px;
} */
.profile-info {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.profile-info h6 {
  margin: 0.5rem 0;
  font-weight: 500;
}
.profile-info p {
  margin: 0.5rem 0;
}
.profile-image {
  height: 100px;
  width: 100px;
  border: solid 1px red;
}

.mgmtImg {
  width: 100px;
  height: 100px;
  /* border: 1px solid var(--Maingray); */
  border-radius: 8%;
  background-color: rgb(151, 151, 151);
  /* margin: 0 auto; */
}

.contentLogo img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8%;
}

.staffImg {
  width: 50px;
  height: 50px;
  border: 1px solid var(--Maingray);
  border-radius: 8%;
  background-color: rgb(151, 151, 151);
  /* margin: 0 auto; */
}

.staffContentLogo img {
  width: 50px;
  height: 50px;
  border-radius: 8%;
  object-fit: cover;
}

.imagespan {
  background: green;
  width: 40px;
  padding: 3px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.imagespan p {
  margin-bottom: 0px !important;
  color: white;
  text-align: center;
  font-weight: bold;
}

.actvspan {
  background: green;
  color: white;
  width: 75px;
  padding: 3px;
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.actvspan:hover {
  color: white;
}
.downloadspan {
  background: green;
  color: white;
  width: 30px;
  padding: 3px;
  margin: 3px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.downloadspan:hover {
  color: white;
}
.editspan {
  background: #004aad;
  color: white;
  width: 30px;
  padding: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.deletespan {
  background: rgb(255, 0, 2);
  color: white;
  width: 30px;
  padding: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.editspan:hover,
.deletespan:hover {
  color: white;
}

.notispan {
  background: #004aad;
  color: white;
  width: 75px;
  padding: 3px;
  margin-top: 3px;
  /* margin-bottom: 3px; */
  font-size: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.notispan:hover {
  color: white;
}

.fontHeader {
  font-size: 23px;
  color: #656565;
  text-align: left;
}
.contentMainSection {
  padding: 15px 14px 10px 19px !important;
}

/* Basic.css */
.basic-center {
  /* border: 1px solid red; */
  margin-top: 13px;
  /* height: 370px; */
  overflow-y: hidden;
}

.basic-dyno {
  /* border: 1px solid blue; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  grid-row-gap: 0.5rem;
  grid-column-gap: 20px;
  height: 280px;
  overflow-y: scroll;
}

.shift-basic-dyno {
  /* border: 1px solid blue; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(215px, 1fr));
  grid-row-gap: 0.5rem;
  grid-column-gap: 20px;
  height: 280px;
  overflow-y: scroll;
}
.service-center {
  margin-top: 13px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 20px;
  /* overflow-y: scroll; */
}

.basic-info {
  text-align: left;
  padding-left: 10px;
}

.basic-title {
  font-size: 14px;
  font-weight: 600;
}

.shift-basic-info {
  text-align: left;
  padding-left: 10px;
  /* height: 20px; */
  /* height: fit-content; */
}

.service-content {
  overflow-y: auto;
  height: 290px;
}
