.productpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50%;
  height: 65vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.productpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  /* height: 50vh; */
  height: 57vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.bankpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 57vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.leavepopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.shiftpopupBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 53vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.documentBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  /* height: 46vh; */
  height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.shiftpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50%;
  height: 67vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

@media screen and (max-width: 900px) {
  .shiftpopup-inner,
  .productpopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .shiftpopup-inner,
  .productpopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}

