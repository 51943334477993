.attpopup-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  display: none;
}

.attpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 40%;
  height: 50vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  display: none;
  border-radius: 6px;
}

.attpopupBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 39vh;
  overflow-y: auto;
  padding-bottom: 3rem;
}

.disDate ._2xcMq {
  display: none !important;
}
.disDate ._1oYOS {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

.attpopupFooter {
  /* border: 1px solid red; */
  position: absolute;
  z-index: 5000;
  bottom: 5px;
  right: 1px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 900px) {
  .attpopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .attpopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
  .from-date {
    margin-bottom: 10px;
  }
}

.anticon-close-circle {
  display: none;
}
.ant-picker {
  width: 100%;
  border: none !important;
  background-color: #efefef7d !important;
  border-radius: 3px !important;
}
.ant-picker-time-panel-column {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.ant-picker-time-panel-column::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  /* display: none; */
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.ant-picker-time-panel-column::-webkit-scrollbar-track {
  background-color: transparent;
}

.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 1rem;
  width: 40px;
}
.ant-picker-focused {
  box-shadow: none !important;
}

.attRoute {
  position: relative;
}

.attRoute .downloadApp {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.attRoute .downloadApp a {
  color: #000;
}

/* .attRoute .downloadApp a:hover{
  color: var(--color);
} */

.attRoute .downloadApp a:hover::after {
  content: "https://play.google.com/store/apps/details?id=com.eliteinfotech.elitewifitool";
  display: inline-block;
  position: absolute;
  top: -20px;
  right: 0;
  width: max-content;
  opacity: 0.7;
}
