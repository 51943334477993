/* --tax-- */

.tax-calculator .uk-button {
  color: var(--color);
  padding: 0 1rem;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid transparent;
  line-height: 35px;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
}

.tax-calculator .approve-btn {
  background-color: var(--color);
  color: #fff;
  margin-right: 0.5rem;
}

.tax-calculator .approve-btn:hover {
  background: #fff;
  color: var(--color);
  border: 1px solid var(--color);
}

.tax-calculator .cancel-btn {
  background-color: #ff4949;
  color: #fff;
  margin-right: 0.5rem;
}

.tax-calculator .cancel-btn:hover {
  background: #fff;
  color: #ff4949;
  border: 1px solid #ff4949;
}
.tax-calculator .row > * {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
.taxForm h5 {
  font-weight: bold;
}
.sticky-tax-top {
  position: sticky;
  top: 20px;
  height: 100%;
  z-index: 1;
}
.tax-result h3 {
  /* font-weight: bold; */
  font-size: 14px;
  margin: 0;
  margin-bottom: 0.5rem;
  color: var(--color);
  text-transform: capitalize;
}
.tax-result .tax-wrapper span {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}
.tax-result .tax-wrapper {
  padding: 1rem 2rem;
  background: var(--bg);
  /* text-align: center; */
  margin-bottom: 1rem;
  border-radius: 0.375rem;
  position: relative;
}
.tax-result .tax-wrapper .icon {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--bg);
  position: absolute;
  right: 0;
  top: 0;
}
/* .tax-result .tax-wrapper {
    padding: 1.3rem;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-bottom: none;
  } */
/* .tax-result .setBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  } */

.tax-result .tax-slab {
  background: var(--color);
  padding: 2rem;
  margin-top: 2.5rem;
}
.tax-result .tax-slab h3 {
  color: #fff;
  text-align: center;
  font-weight: bold;
}
.tax-result .tax-slab h3 span {
  font-size: 30px;
  letter-spacing: 3px;
  margin-top: 0.5rem;
  display: block;
}

@media screen and (max-width: 959.9px) {
  .sticky-tax-top {
    position: static;
  }
  .tax-result {
    width: 50%;
    margin: 3rem auto;
  }
}
@media screen and (max-width: 767.9px) {
  .tax-result {
    width: 75%;
  }
}
@media screen and (max-width: 577px) {
  .tax-result {
    width: 100%;
  }
}

/* --table css-- */

.tax-table .tableHead {
  background-color: var(--color) !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding: 10px 1rem !important;
  font-weight: bold !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.tax-table .tableBody {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 13px !important;
  padding: 0.3rem 1rem !important;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.tax-table tr:nth-of-type(odd) {
  background-color: #fff;
}

.tax-table .tableFooter {
  background-color: #f8f8ff !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 13px !important;
  padding: 0.3rem 1rem !important;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: bold;
}
