/* Template: Sync - Free Mobile App Landing Page HTML Template
   Author: Inovatik
   Created: Dec 2019
   Description: Master CSS file
*/
/*****************************************
Table Of Contents:

01. General Styles
02. Preloader
03. Navigation
04. Header
05. Small Features
06. Description 1
07. Description 1 Details Lightbox
08. Description 2
09. Features
10. Screenshots
11. Testimonials
12. Statistics
13. Download
14. Footer
15. Copyright
16. Back To Top Button
17. Extra Pages
18. Media Queries
******************************************/
/*****************************************
Colors:
- Backgrounds - light gray #f7fafd
- Backgrounds - gray #e8eff6
- Buttons - red #ff556e
- Headings text, navbar links - black #333
- Body text - dark gray #777
******************************************/
/******************************/
/*     01. General Styles     */
/******************************/
.index-page {
  /* Form Success And Error Message Formatting */
  /* end of form success and error message animation - Animate.css */
  /* Fade-move Animation For Details Lightbox - Magnific Popup */
  /* at start */
  /* animate in */
  /* animate out */
  /* dark overlay, start state */
  /* animate in */
  /* animate out */
  /* end of fade animation for details lightbox - magnific popup */
  /*************************/
  /*     02. Preloader     */
  /*************************/
  /**************************/
  /*     03. Navigation     */
  /**************************/
  /* Dropdown Menu */
  /* end of dropdown menu */
  /*********************/
  /*    04. Header     */
  /*********************/
  /******************************/
  /*     05. Small Features     */
  /******************************/
  /*****************************/
  /*     06. Description 1     */
  /*****************************/
  /**********************************************/
  /*     07. Description 1 Details Lightbox     */
  /**********************************************/
  /* Action Button */
  /* end of action Button */
  /* Back Button */
  /* end of back button */
  /* Close X Button */
  /* end of close x button */
  /****************************/
  /*     08. Description 2    */
  /****************************/
  /************************/
  /*     09. Features     */
  /************************/
  /***************************/
  /*     10. Screenshots     */
  /***************************/
  /****************************/
  /*     11. Testimonials     */
  /****************************/
  /**************************/
  /*     12. Statistics     */
  /**************************/
  /************************/
  /*     13. Download     */
  /************************/
  /**********************/
  /*     14. Footer     */
  /**********************/
  /*************************/
  /*     15. Copyright     */
  /*************************/
  /**********************************/
  /*     16. Back To Top Button     */
  /**********************************/
  /***************************/
  /*     17. Extra Pages     */
  /***************************/
}
.index-page p {
  color: #777;
  font: 400 1rem/1.75rem "Open Sans", sans-serif;
}
.index-page .p-large {
  font: 400 1.125rem/1.875rem "Open Sans", sans-serif;
}
.index-page .p-small {
  font: 400 0.875rem/1.5rem "Open Sans", sans-serif;
}
.index-page .p-heading {
  margin-bottom: 4rem;
  text-align: center;
}
.index-page h1 {
  color: #333;
  font: 700 2.625rem/3.375rem "Open Sans", sans-serif;
}
.index-page h2 {
  color: #333;
  font: 700 2.125rem/2.75rem "Open Sans", sans-serif;
}
.index-page h3 {
  color: #333;
  font: 700 1.75rem/2.375rem "Open Sans", sans-serif;
}
.index-page h4 {
  color: #333;
  font: 700 1.25rem/1.75rem "Open Sans", sans-serif;
}
.index-page h5 {
  color: #333;
  font: 700 1.125rem/1.625rem "Open Sans", sans-serif;
}
.index-page h6 {
  color: #333;
  font: 700 1rem/1.5rem "Open Sans", sans-serif;
}
.index-page .testimonial-text {
  font-style: italic;
}
.index-page .testimonial-author {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
}
.index-page .li-space-lg li {
  margin-bottom: 0.625rem;
  position: relative;
  display: flex;
}
.index-page a {
  color: #555;
  text-decoration: underline;
}
.index-page a:hover {
  color: #555;
  text-decoration: underline;
}
.index-page a.red {
  color: #ff556e;
}
.index-page .btn-solid-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #ff556e;
  border-radius: 2rem;
  background-color: #ff556e;
  color: #fff;
  font: 700 0.875rem/0 "Open Sans", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}
.index-page .btn-solid-reg:hover {
  border: 0.125rem solid #ff556e;
  background-color: transparent;
  color: #ff556e;
  text-decoration: none;
}
.index-page .btn-solid-lg {
  display: inline-block;
  padding: 1.5rem 2.375rem 1.5rem 2.375rem;
  border: 0.125rem solid #ff556e;
  border-radius: 2rem;
  background-color: #ff556e;
  color: #fff;
  font: 700 0.875rem/0 "Open Sans", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}
.index-page .btn-solid-lg:hover {
  border: 0.125rem solid #ff556e;
  background-color: transparent;
  color: #ff556e;
  text-decoration: none;
}
.index-page .btn-solid-lg .fab {
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: top;
}
.index-page .btn-solid-lg .fab.fa-google-play {
  font-size: 1rem;
}
.index-page .btn-outline-reg {
  display: inline-block;
  padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 700 0.875rem/0 "Open Sans", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}
.index-page .btn-outline-reg:hover {
  border: 0.125rem solid #333;
  background-color: #333;
  color: #fff;
  text-decoration: none;
}
.index-page .btn-outline-lg {
  display: inline-block;
  padding: 1.5rem 2.375rem 1.5rem 2.375rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 700 0.875rem/0 "Open Sans", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}
.index-page .btn-outline-lg:hover {
  border: 0.125rem solid #333;
  background-color: #333;
  color: #fff;
  text-decoration: none;
}
.index-page .btn-outline-sm {
  display: inline-block;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border: 0.125rem solid #333;
  border-radius: 2rem;
  background-color: transparent;
  color: #333;
  font: 700 0.875rem/0 "Open Sans", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}
.index-page .btn-outline-sm:hover {
  border: 0.125rem solid #333;
  background-color: #333;
  color: #fff;
  text-decoration: none;
}
.index-page .form-group {
  position: relative;
  margin-bottom: 1.25rem;
}
.index-page .form-group.has-error.has-danger {
  margin-bottom: 0.625rem;
}
.index-page .form-group.has-error.has-danger .help-block.with-errors ul {
  margin-top: 0.375rem;
}
.index-page .label-control {
  position: absolute;
  top: 0.87rem;
  left: 1.375rem;
  color: #777;
  opacity: 1;
  font: 400 0.875rem/1.375rem "Open Sans", sans-serif;
  cursor: text;
  transition: all 0.2s ease;
}
.index-page .form-control-input:focus + .label-control,
.index-page .form-control-input.notEmpty + .label-control,
.index-page .form-control-textarea:focus + .label-control,
.index-page .form-control-textarea.notEmpty + .label-control {
  top: 0.125rem;
  opacity: 1;
  font-size: 0.75rem;
  font-weight: 700;
}
.index-page .form-control-input,
.index-page .form-control-select {
  display: block; /* needed for proper display of the label in Firefox, IE, Edge */
  width: 100%;
  padding-top: 1.0625rem;
  padding-bottom: 0.0625rem;
  padding-left: 1.3125rem;
  border: 1px solid #c4d8dc;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #777;
  font: 400 0.875rem/1.875rem "Open Sans", sans-serif;
  transition: all 0.2s;
  -webkit-appearance: none; /* removes inner shadow on form inputs on ios safari */
}
.index-page .form-control-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 3rem;
}
.index-page .form-control-textarea {
  display: block; /* used to eliminate a bottom gap difference between Chrome and IE/FF */
  width: 100%;
  height: 8rem; /* used instead of html rows to normalize height between Chrome and IE/FF */
  padding-top: 1.25rem;
  padding-left: 1.3125rem;
  border: 1px solid #c4d8dc;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #777;
  font: 400 0.875rem/1.75rem "Open Sans", sans-serif;
  transition: all 0.2s;
}
.index-page .form-control-input:focus,
.index-page .form-control-select:focus,
.index-page .form-control-textarea:focus {
  border: 1px solid #a1a1a1;
  outline: none; /* Removes blue border on focus */
}
.index-page .form-control-input:hover,
.index-page .form-control-select:hover,
.index-page .form-control-textarea:hover {
  border: 1px solid #a1a1a1;
}
.index-page input[type=checkbox] {
  vertical-align: -10%;
  margin-right: 0.5rem;
}
.index-page .form-control-submit-button {
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  border: 0.125rem solid #ff556e;
  border-radius: 1.5rem;
  background-color: #ff556e;
  color: #fff;
  font: 700 0.875rem/0 "Open Sans", sans-serif;
  cursor: pointer;
  transition: all 0.2s;
}
.index-page .form-control-submit-button:hover {
  border: 0.125rem solid #ff556e;
  background-color: transparent;
  color: #ff556e;
}
.index-page #pmsgSubmit.h3.text-center.tada.animated,
.index-page #pmsgSubmit.h3.text-center {
  display: block;
  margin-bottom: 0;
  color: #ff556e;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1rem;
}
.index-page .help-block.with-errors .list-unstyled {
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: left;
}
.index-page .help-block.with-errors ul {
  margin-bottom: 0;
}
.index-page .tada {
  animation-name: tada;
}
.index-page .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.index-page .my-mfp-slide-bottom .zoom-anim-dialog {
  opacity: 0;
  transition: all 0.2s ease-out;
  transform: translateY(-1.25rem) perspective(37.5rem) rotateX(10deg);
}
.index-page .my-mfp-slide-bottom.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  transform: translateY(0) perspective(37.5rem) rotateX(0);
}
.index-page .my-mfp-slide-bottom.mfp-removing .zoom-anim-dialog {
  opacity: 0;
  transform: translateY(-0.625rem) perspective(37.5rem) rotateX(10deg);
}
.index-page .my-mfp-slide-bottom.mfp-bg {
  opacity: 0;
  transition: opacity 0.2s ease-out;
}
.index-page .my-mfp-slide-bottom.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.index-page .my-mfp-slide-bottom.mfp-removing.mfp-bg {
  opacity: 0;
}
.index-page .fadeOut {
  animation: fadeOut 0.8s;
}
.index-page .spinner-wrapper {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f7fafd;
}
.index-page .spinner {
  position: absolute;
  top: 50%; /* centers the loading animation vertically one the screen */
  left: 50%; /* centers the loading animation horizontally one the screen */
  width: 3.75rem;
  height: 1.25rem;
  margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */
  text-align: center;
}
.index-page .spinner > div {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  background-color: #ff556e;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.index-page .spinner .bounce1 {
  animation-delay: -0.32s;
}
.index-page .spinner .bounce2 {
  animation-delay: -0.16s;
}
.index-page .fadeIn {
  animation: fadeIn 0.6s;
}
.index-page .navbar-custom {
  background-color: #f7fafd;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
  transition: all 0.2s;
}
.index-page .navbar-custom .navbar-brand.logo-image img {
  width: 6.6875rem;
  height: 2.25rem;
}
.index-page .navbar-custom .navbar-brand.logo-text {
  font: 700 2rem/1rem "Open Sans", sans-serif;
  color: #333;
  text-decoration: none;
}
.index-page .navbar-custom .navbar-nav {
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
.index-page .navbar-custom .nav-item .nav-link {
  padding: 0.625rem 0.75rem;
  color: #333;
  text-decoration: none;
  transition: all 0.2s ease;
}
.index-page .navbar-custom .nav-item .nav-link:hover,
.index-page .navbar-custom .nav-item .nav-link.active {
  color: #ff556e;
}
.index-page .navbar-custom .dropdown:hover > .dropdown-menu {
  display: block; /* this makes the dropdown menu stay open while hovering it */
  min-width: auto;
  animation: fadeDropdown 0.2s; /* required for the fade animation */
}
.index-page .navbar-custom .dropdown-toggle {
  cursor: default;
}
.index-page .navbar-custom .dropdown-toggle:focus {
  /* removes dropdown outline on focus */
  outline: 0;
}
.index-page .navbar-custom .dropdown-menu {
  margin-top: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: #f7fafd;
}
.index-page .navbar-custom .dropdown-item {
  color: #333;
  text-decoration: none;
}
.index-page .navbar-custom .dropdown-item:hover {
  background-color: #f7fafd;
}
.index-page .navbar-custom .dropdown-item .item-text {
  font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
}
.index-page .navbar-custom .dropdown-item:hover .item-text {
  color: #ff556e;
}
.index-page .navbar-custom .dropdown-divider {
  width: 100%;
  height: 1px;
  margin: 0.75rem auto 0.725rem auto;
  border: none;
  background-color: #c4d8dc;
  opacity: 0.2;
}
.index-page .navbar-custom .nav-item .btn-outline-sm {
  margin-top: 0.125rem;
  margin-bottom: 1.375rem;
  margin-left: 0.5rem;
  border: 0.125rem solid #ff556e;
  color: #ff556e;
}
.index-page .navbar-custom .nav-item .btn-outline-sm:hover {
  border: 0.125rem solid #ff556e;
  background-color: #ff556e;
  color: #fff;
}
.index-page .navbar-toggler:focus {
  box-shadow: none !important;
}
.index-page .navbar-custom .navbar-toggler {
  padding: 0.5rem;
  line-height: 0;
  border: none;
  color: #333;
  font-size: 2rem;
  background: #ff556e;
}
.index-page .navbar-custom .navbar-toggler .navbar-toggler-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.index-page .navbar-custom button[aria-expanded=false] .navbar-toggler-awesome.fas.fa-times {
  display: none;
}
.index-page .navbar-custom button[aria-expanded=false] .navbar-toggler-awesome.fas.fa-bars {
  display: inline-block;
}
.index-page .navbar-custom button[aria-expanded=true] .navbar-toggler-awesome.fas.fa-bars {
  display: none;
}
.index-page .navbar-custom button[aria-expanded=true] .navbar-toggler-awesome.fas.fa-times {
  display: inline-block;
  margin-right: 0.125rem;
}
.index-page .header {
  position: relative;
  overflow: hidden;
  padding-top: 8rem;
  padding-bottom: 3rem;
  background-color: #f7fafd;
  text-align: center;
}
.index-page .header .text-container {
  margin-bottom: 3rem;
}
.index-page .header h1 {
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}
.index-page .header h1:before {
  content: url(../images/lines.png);
  position: absolute;
  top: 0;
  left: -25px;
  transform: translate(-50%, -50%);
}
.index-page .header h1:after {
  content: url(../images/lines.png);
  position: absolute;
  top: 0;
  right: -65px;
  transform: translate(-50%, -50%) rotate(90deg);
}
.index-page .header .p-heading {
  margin-bottom: 2rem;
}
.index-page .header .btn-solid-lg {
  margin-right: 0.5rem;
  margin-bottom: 1.125rem;
  margin-left: 0.5rem;
}
.index-page .header .deco-white-circle-1,
.index-page .header .deco-white-circle-2,
.index-page .header .deco-blue-circle,
.index-page .header .deco-yellow-circle,
.index-page .header .deco-green-diamond {
  display: none;
}
.index-page .cards-1 {
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: #f7fafd;
  text-align: center;
}
.index-page .cards-1 .card {
  max-width: 11rem;
  margin-right: auto;
  margin-bottom: 3rem;
  margin-left: auto;
  padding: 0;
  border: none;
  background-color: transparent;
}
.index-page .cards-1 .card-image {
  width: 7.5rem;
  height: 7.5rem;
  margin-right: auto;
  margin-bottom: 1.5rem;
  margin-left: auto;
  border-radius: 50%;
  background-color: #ebe7fa;
}
.index-page .cards-1 .card-image .fas {
  color: #aa7eec;
  font-size: 3.25rem;
  line-height: 7.5rem;
}
.index-page .cards-1 .card-image.green {
  background-color: #daf4ef;
}
.index-page .cards-1 .card-image.green .fas {
  color: #19ca94;
}
.index-page .cards-1 .card-image.red {
  background-color: #f8e5ea;
}
.index-page .cards-1 .card-image.red .fas {
  color: #ff556e;
}
.index-page .cards-1 .card-image.yellow {
  background-color: #f8f1de;
}
.index-page .cards-1 .card-image.yellow .fas {
  color: #ffb30f;
}
.index-page .cards-1 .card-image.blue {
  background-color: #e1f2fa;
}
.index-page .cards-1 .card-image.blue .fas {
  color: #50b9e8;
}
.index-page .cards-1 .card-body {
  padding: 0;
}
.index-page .cards-1 .card-title {
  margin-bottom: 0.5rem;
}
.index-page .basic-1 {
  padding-top: 3rem;
  padding-bottom: 8rem;
  background-color: #f7fafd;
}
.index-page .basic-1 .image-container {
  margin-bottom: 5rem;
}
.index-page .basic-1 h2 {
  margin-bottom: 1.5rem;
}
.index-page .basic-1 .list-unstyled {
  margin-bottom: 1.75rem;
}
.index-page .basic-1 .list-unstyled .fas {
  color: #777;
  font-size: 0.5rem;
  line-height: 1.75rem;
}
.index-page .basic-1 .list-unstyled .media-body {
  margin-left: 0.5rem;
}
.index-page .lightbox-basic {
  position: relative;
  max-width: 71.875rem;
  margin: 2.5rem auto;
  padding: 3rem 1rem;
  background-color: #fff;
  text-align: left;
}
.index-page .lightbox-basic .image-container {
  margin-bottom: 3rem;
  text-align: center;
}
.index-page .lightbox-basic h3 {
  margin-bottom: 0.5rem;
}
.index-page .lightbox-basic hr {
  width: 2.75rem;
  margin-top: 0.125rem;
  margin-bottom: 1rem;
  margin-left: 0;
  border-top: 0.125rem solid #e0e0e0;
}
.index-page .lightbox-basic h4 {
  margin-top: 1.75rem;
  margin-bottom: 0.625rem;
}
.index-page .lightbox-basic .list-unstyled {
  margin-bottom: 1.5rem;
}
.index-page .lightbox-basic .list-unstyled .far {
  color: #19ca94;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.index-page .lightbox-basic .list-unstyled .media-body {
  margin-left: 0.625rem;
}
.index-page .lightbox-basic .list-unstyled {
  margin-bottom: 1.5rem;
}
.index-page .lightbox-basic .btn-solid-reg.mfp-close {
  position: relative;
  width: auto;
  height: auto;
  color: #fff;
  opacity: 1;
}
.index-page .lightbox-basic .btn-solid-reg.mfp-close:hover {
  color: #ff556e;
}
.index-page .lightbox-basic .btn-outline-reg.mfp-close.as-button {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin-left: 0.375rem;
  padding: 1.25rem 2.25rem 1.25rem 2.25rem;
  border: 0.125rem solid #333;
  color: #333;
  opacity: 1;
}
.index-page .lightbox-basic .btn-outline-reg.mfp-close.as-button:hover {
  color: #fff;
}
.index-page .lightbox-basic button.mfp-close.x-button {
  position: absolute;
  top: -0.125rem;
  right: -0.125rem;
  width: 2.75rem;
  height: 2.75rem;
  color: #555;
}
.index-page .tabs {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #e8eff6;
}
.index-page .tabs .tabs-container {
  margin-bottom: 5rem;
}
.index-page .tabs .nav-tabs {
  margin-bottom: 1.125rem;
  border: none;
}
.index-page .tabs .nav-item {
  width: 100%;
  margin-bottom: 0.75rem;
  text-align: center;
}
.index-page .tabs .nav-link {
  padding: 0.5rem 1.5rem;
  border-radius: 0;
  background-color: #f7fafd;
  color: #777;
  font-weight: 700;
  font-size: 1.125rem;
  text-decoration: none;
  transition: all 0.2s ease;
}
.index-page .tabs .nav-link.active {
  background-color: #9a67e7;
  color: #fff;
}
.index-page .tabs .nav-link:hover {
  background-color: #9a67e7;
  color: #fff;
}
.index-page .tabs .nav-link .fas,
.index-page .tabs .nav-link .far {
  margin-right: 0.375rem;
}
.index-page .tabs .li-space-lg li {
  margin-bottom: 0.875rem;
}
.index-page .tabs .list-unstyled .fas,
.index-page .tabs .list-unstyled .far {
  color: #19ca94;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.index-page .tabs .list-unstyled .media-body {
  margin-left: 0.625rem;
}
.index-page .tabs #tab-1 .list-unstyled {
  margin-bottom: 1.5rem;
}
.index-page .tabs #tab-1 .btn-solid-reg {
  margin-right: 0.5rem;
}
.index-page .basic-2 {
  padding-top: 7rem;
  padding-bottom: 5rem;
  background-color: #f7fafd;
}
.index-page .basic-2 h2 {
  margin-bottom: 0.875rem;
  text-align: center;
}
.index-page .basic-2 .li-space-lg li {
  margin-bottom: 1.375rem;
}
.index-page .basic-2 .li-space-lg.first li:last-of-type {
  margin-bottom: 4rem;
}
.index-page .basic-2 .fa-stack {
  width: 2em;
  margin-right: 0.625rem;
  font-size: 1.25rem;
}
.index-page .basic-2 .fa-stack-2x {
  color: #aa7eec;
}
.index-page .basic-2 .fa-stack-2x.green {
  color: #19ca94;
}
.index-page .basic-2 .fa-stack-2x.red {
  color: #ff556e;
}
.index-page .basic-2 .fa-stack-2x.yellow {
  color: #ffb30f;
}
.index-page .basic-2 .fa-stack-2x.blue {
  color: #50b9e8;
}
.index-page .basic-2 .fa-stack-1x {
  color: #fff;
}
.index-page .basic-2 .media-body h4 {
  margin-bottom: 0.25rem;
}
.index-page .basic-2 img {
  display: block;
  width: 100%;
  max-width: 20rem;
  margin-right: auto;
  margin-bottom: 5rem;
  margin-left: auto;
}
.index-page .slider {
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #e8eff6;
}
.index-page .slider .slider-container {
  position: relative;
}
.index-page .slider .swiper-container {
  position: static;
  width: 90%;
  text-align: center;
}
.index-page .slider .swiper-button-prev,
.index-page .slider .swiper-button-next {
  top: 50%;
  width: 1.125rem;
}
.index-page .slider .swiper-button-prev:focus,
.index-page .slider .swiper-button-next:focus {
  /* even if you can't see it chrome you can see it on mobile device */
  outline: none;
}
.index-page .slider .swiper-button-prev {
  left: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23777777'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}
.index-page .slider .swiper-button-next {
  right: -0.5rem;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2028%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23777777'%2F%3E%3C%2Fsvg%3E");
  background-size: 1.125rem 1.75rem;
}
.index-page .cards-2 {
  padding-top: 7rem;
  padding-bottom: 3rem;
  background-color: #f7fafd;
  text-align: center;
}
.index-page .cards-2 h2 {
  margin-bottom: 3.25rem;
}
.index-page .cards-2 .card {
  max-width: 22rem;
  margin-right: auto;
  margin-bottom: 4.75rem;
  margin-left: auto;
  border: 0;
  background-color: transparent;
}
.index-page .cards-2 .fa-star {
  margin-right: 0.25rem;
  color: #ffb30f;
  font-size: 1.375rem;
}
.index-page .cards-2 .testimonial-line {
  width: 3rem;
  margin-bottom: 1rem;
  border-top: 0.125rem solid #e0e0e0;
}
.index-page .cards-2 .card-body {
  padding: 0;
}
.index-page .cards-2 .testimonial-text {
  margin-bottom: 0.625rem;
}
.index-page .cards-2 .testimonial-author {
  color: #333;
}
.index-page .cards-2 .image-container {
  margin-bottom: 5.25rem;
  padding: 2.5rem 2rem 0 2rem;
  border-radius: 0.5rem;
  background-color: #fff;
}
.index-page .cards-2 .image-container img {
  margin-right: 1rem;
  margin-bottom: 2.5rem;
  margin-left: 1rem;
}
.index-page .counter {
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: #e8eff6;
  text-align: center;
}
.index-page .counter #counter {
  margin-bottom: 0.75rem;
}
.index-page .counter #counter .cell {
  display: inline-block;
  width: 6.25rem;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 1.5rem;
  vertical-align: top;
}
.index-page .counter #counter .fas {
  margin-bottom: 0.875rem;
  font-size: 2.5rem;
  color: #aa7eec;
}
.index-page .counter #counter .fas.green {
  color: #19ca94;
}
.index-page .counter #counter .fas.red {
  color: #ff556e;
}
.index-page .counter #counter .fas.yellow {
  color: #ffb30f;
}
.index-page .counter #counter .fas.blue {
  color: #50b9e8;
}
.index-page .counter #counter .counter-value {
  color: #333;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 4.25rem;
  vertical-align: middle;
}
.index-page .counter #counter .counter-info {
  margin-bottom: 0;
  vertical-align: middle;
}
.index-page .basic-3 {
  position: relative;
  overflow: hidden;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #f7fafd;
  text-align: center;
}
.index-page .basic-3 .image-container {
  margin-bottom: 2rem;
}
.index-page .basic-3 .p-large {
  margin-bottom: 1.75rem;
}
.index-page .basic-3 .btn-solid-lg {
  margin-right: 0.5rem;
  margin-bottom: 1.125rem;
  margin-left: 0.5rem;
}
.index-page .basic-3 .deco-white-circle-1,
.index-page .basic-3 .deco-white-circle-2,
.index-page .basic-3 .deco-blue-circle,
.index-page .basic-3 .deco-yellow-circle,
.index-page .basic-3 .deco-green-diamond {
  display: none;
}
.index-page .footer {
  padding-top: 4rem;
  padding-bottom: 1rem;
  border-top: 1px solid #c8d4d6;
  background-color: #f7fafd;
}
.index-page .footer p,
.index-page .footer h5,
.index-page .footer ul,
.index-page .footer a {
  color: #999;
}
.index-page .footer a {
  text-decoration: none;
}
.index-page .footer .footer-col {
  display: inline-block;
  width: 14.5rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  vertical-align: top;
}
.index-page .footer h5 {
  margin-bottom: 0.625rem;
}
.index-page .footer .li-space-lg li {
  margin-bottom: 0.375rem;
}
.index-page .footer .list-unstyled .fab,
.index-page .footer .list-unstyled .fas {
  width: 0.875rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.index-page .footer .list-unstyled .media-body {
  margin-left: 0.625rem;
}
.index-page .footer .fa-stack {
  width: 2em;
  margin-bottom: 0.75rem;
  margin-right: 0.375rem;
  font-size: 1.25rem;
}
.index-page .footer .fa-stack .fa-stack-1x {
  color: #fff;
  transition: all 0.2s ease;
}
.index-page .footer .fa-stack .fa-stack-2x {
  color: #999;
  transition: all 0.2s ease;
}
.index-page .footer .fa-stack:hover .fa-stack-1x {
  color: #fff;
}
.index-page .footer .fa-stack:hover .fa-stack-2x {
  color: #555;
}
.index-page .copyright {
  padding-bottom: 0.75rem;
  background-color: #f7fafd;
  text-align: center;
}
.index-page .copyright p,
.index-page .copyright a {
  color: #999;
}
.index-page .copyright a {
  text-decoration: none;
}
.index-page a.back-to-top {
  position: fixed;
  z-index: 999;
  right: 0.75rem;
  bottom: 0.75rem;
  display: none;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 1.875rem;
  /* background: #333 url("../images/up-arrow.png") no-repeat center 47%; */
  background-size: 1.125rem 1.125rem;
  text-indent: -9999px;
}
.index-page a:hover.back-to-top {
  background-color: #555;
}
.index-page .ex-header {
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-color: #f7fafd;
  text-align: center;
}
.index-page .ex-basic-1 {
  padding-top: 2rem;
  padding-bottom: 0.875rem;
  background-color: #e8eff6;
}
.index-page .ex-basic-1 .breadcrumbs {
  margin-bottom: 1.125rem;
}
.index-page .ex-basic-1 .breadcrumbs .fa {
  margin-right: 0.5rem;
  margin-left: 0.625rem;
  font-size: 0.875rem;
}
.index-page .ex-basic-2 {
  padding-top: 4.75rem;
  padding-bottom: 4rem;
  background-color: #f7fafd;
}
.index-page .ex-basic-2 h3 {
  margin-bottom: 1rem;
}
.index-page .ex-basic-2 .text-container {
  margin-bottom: 3.625rem;
}
.index-page .ex-basic-2 .text-container.last {
  margin-bottom: 0;
}
.index-page .ex-basic-2 .text-container.dark {
  padding: 1.625rem 1.5rem 0.75rem 2rem;
  background-color: #e8eff6;
}
.index-page .ex-basic-2 .image-container-large {
  margin-bottom: 4rem;
}
.index-page .ex-basic-2 .image-container-large img {
  border-radius: 0.375rem;
}
.index-page .ex-basic-2 .image-container-small img {
  border-radius: 0.375rem;
}
.index-page .ex-basic-2 .list-unstyled .fas {
  color: #777;
  font-size: 0.5rem;
  line-height: 1.75rem;
}
.index-page .ex-basic-2 .list-unstyled .media-body {
  margin-left: 0.625rem;
}
.index-page .ex-basic-2 .form-container {
  margin-top: 3rem;
}
.index-page .ex-basic-2 .btn-solid-reg {
  margin-top: 1.5rem;
}

/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .index-page .label-control {
    top: 0.9375rem;
  }
}
/* IE10+ hack to solve lower label text position compared to the rest of the browsers */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .index-page .form-control-input {
    padding-top: 1.25rem;
    padding-bottom: 0.75rem;
    line-height: 1.75rem;
  }
  .index-page .form-control-select {
    padding-top: 0.875rem;
    padding-bottom: 0.75rem;
    height: 3.125rem;
    line-height: 2.125rem;
  }
}
/* end of form success and error message formatting */
/* Form Success And Error Message Animation - Animate.css */
@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
/* end of fade-move animation for details lightbox - magnific popup */
/* Fade Animation For Image Lightbox - Magnific Popup */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
@keyframes fadeDropdown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*****************************/
/*     18. Media Queries     */
/*****************************/
/* Min-width 768px */
@media (min-width: 768px) {
  .index-page {
    /* Header */
    /* end of header */
    /* Small Features */
    /* end of small features */
    /* Description 2 */
    /* end of description 2 */
    /* Screenshots */
    /* end of screenshots */
    /* Download */
    /* end of download */
    /* Extra Pages */
    /* end of extra pages */
  }
  .index-page .header .text-container {
    margin-bottom: 6rem;
  }
  .index-page .header .btn-solid-lg {
    margin-bottom: 0;
    margin-left: 0;
  }
  .index-page .cards-1 .card {
    display: inline-block;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 11rem;
    max-width: 100%;
    vertical-align: top;
  }
  .index-page .tabs .nav-item {
    width: auto;
    margin-right: 0.625rem;
  }
  .index-page .slider .swiper-button-prev {
    left: -0.625rem;
  }
  .index-page .slider .swiper-button-next {
    right: -0.625rem;
  }
  .index-page .basic-3 .btn-solid-lg {
    margin-bottom: 0;
    margin-left: 0;
  }
  .index-page .ex-header {
    padding-top: 11rem;
    padding-bottom: 9rem;
  }
  .index-page .ex-basic-2 .text-container.dark {
    padding: 2.5rem 3rem 2rem 3rem;
  }
  .index-page .ex-basic-2 .form-container {
    margin-top: 0;
  }
}
/* end of min-width 768px */
/* Min-width 992px */
@media (min-width: 992px) {
  .index-page {
    /* General Styles */
    /* end of general styles */
    /* Navigation */
    /* end of navigation */
    /* Header */
    /* end of header */
    /* Description 1 */
    /* end of description 1 */
    /* Description 1 Details Lightbox */
    /* end of description 1 details lightbox */
    /* Description 2 */
    /* end of description 2 */
    /* Features */
    /* .basic-2 {
              padding-bottom: 8rem;
          } */
    /* end of features */
    /* Screenshots */
    /* end of screenshots */
    /* Testimonials */
    /* end of testimonials */
    /* Statistics */
    /* end of statistics */
    /* Download */
    /* end of download */
    /* Footer */
    /* end of footer */
    /* Extra Pages */
    /* end of extra pages */
  }
  .index-page .p-heading {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .index-page h1 {
    font-size: 3rem;
    line-height: 3.875rem;
  }
  .index-page h2 {
    font-size: 2.5rem;
    line-height: 3.25rem;
  }
  .index-page .navbar-custom {
    padding: 2.125rem 1.5rem 2.125rem 2rem;
    background: transparent;
    box-shadow: none;
  }
  .index-page .navbar-custom .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
  }
  .index-page .navbar-custom .nav-item .nav-link {
    padding: 0.25rem 0.75rem;
  }
  .index-page .navbar-custom.top-nav-collapse {
    padding: 0.5rem 1.5rem 0.5rem 2rem;
    background-color: #f7fafd;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  }
  .index-page .navbar-custom.top-nav-collapse .nav-item .nav-link {
    color: #333;
  }
  .index-page .navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
  .index-page .navbar-custom.top-nav-collapse .nav-item .nav-link.active {
    color: #ff556e;
  }
  .index-page .navbar-custom .dropdown-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 0.75rem solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
    box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  }
  .index-page .navbar-custom.top-nav-collapse .dropdown-menu {
    border-top: 0.75rem solid rgba(0, 0, 0, 0);
    box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.1);
  }
  .index-page .navbar-custom .dropdown-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .index-page .navbar-custom .dropdown-divider {
    width: 84%;
  }
  .index-page .navbar-custom .nav-item .btn-outline-sm {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0.625rem;
  }
  .index-page .login-mob-btn {
    display: none;
  }
  .index-page .header {
    padding-top: 12rem;
  }
  .index-page .header h1 {
    font-size: 4rem;
    line-height: 4.875rem;
    letter-spacing: -1px;
  }
  .index-page .header .deco-white-circle-1 {
    position: absolute;
    top: 26rem;
    left: -12rem;
    display: block;
    width: 22rem;
    height: 22rem;
  }
  .index-page .header .deco-white-circle-2 {
    position: absolute;
    top: 19rem;
    right: -12rem;
    display: block;
    width: 20rem;
    height: 20rem;
  }
  .index-page .header .deco-blue-circle {
    position: absolute;
    top: 28rem;
    left: 5rem;
    display: block;
    width: 5rem;
    height: 5rem;
  }
  .index-page .header .deco-yellow-circle {
    position: absolute;
    top: 9rem;
    right: 7rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  .index-page .header .deco-green-diamond {
    position: absolute;
    top: 9rem;
    left: 4rem;
    display: block;
    width: 1rem;
    height: 1rem;
  }
  .index-page .basic-1 .image-container {
    margin-bottom: 0;
  }
  .index-page .lightbox-basic {
    padding: 3rem 3rem;
  }
  .index-page .lightbox-basic .image-container {
    margin-bottom: 0;
    text-align: left;
  }
  .index-page .tabs .tabs-container {
    margin-bottom: 0;
  }
  .index-page .basic-2 .list-unstyled {
    margin-top: 2.5rem;
  }
  .index-page .basic-2 .li-space-lg li {
    margin-bottom: 1.875rem;
  }
  .index-page .basic-2 .li-space-lg.first li:last-of-type {
    margin-bottom: 1.875rem;
  }
  .index-page .basic-2 .fa-stack {
    margin-right: 1rem;
    font-size: 2.25rem;
  }
  .index-page .basic-2 .fa-stack .fa-stack-1x {
    font-size: 1.75rem;
  }
  .index-page .basic-2 .first .fa-stack {
    position: absolute;
    right: 0;
  }
  .index-page .basic-2 .first .media-body {
    max-width: 12.625rem;
    text-align: right;
  }
  .index-page .basic-2 img {
    max-width: 100%;
    margin-bottom: 0;
  }
  .index-page .slider .swiper-button-prev {
    left: 0;
  }
  .index-page .slider .swiper-button-next {
    right: 0;
  }
  .index-page .cards-2 .card {
    display: inline-block;
    width: 18.125rem;
    max-width: 100%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    vertical-align: top;
  }
  .index-page .cards-2 .card:first-of-type {
    margin-left: 0;
  }
  .index-page .cards-2 .card:last-of-type {
    margin-right: 0;
  }
  .index-page .counter #counter .cell {
    width: 8.25rem;
  }
  .index-page .counter #counter .fas {
    font-size: 3rem;
  }
  .index-page .counter #counter .counter-value {
    font-size: 4rem;
    line-height: 4.875rem;
  }
  .index-page .basic-3 .p-large {
    width: 51rem;
    margin-right: auto;
    margin-left: auto;
  }
  .index-page .basic-3 .deco-white-circle-1 {
    position: absolute;
    top: 23rem;
    left: -12rem;
    display: block;
    width: 20rem;
    height: 20rem;
  }
  .index-page .basic-3 .deco-white-circle-2 {
    position: absolute;
    top: 7rem;
    right: -12.5rem;
    display: block;
    width: 20rem;
    height: 20rem;
  }
  .index-page .basic-3 .deco-blue-circle {
    position: absolute;
    top: 15rem;
    right: 5rem;
    display: block;
    width: 5rem;
    height: 5rem;
  }
  .index-page .basic-3 .deco-yellow-circle {
    position: absolute;
    top: 31rem;
    left: 6.5rem;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
  }
  .index-page .basic-3 .deco-green-diamond {
    position: absolute;
    top: 8rem;
    right: 12.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
  }
  .index-page .footer .footer-col.first {
    display: none;
  }
  .index-page .footer .footer-col.third,
  .index-page .footer .footer-col.fourth {
    width: 11.5rem;
  }
  .index-page .ex-header h1 {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .index-page .ex-basic-2 {
    padding-bottom: 5rem;
  }
}
/* end of min-width 992px */
/* Min-width 1200px */
@media (min-width: 1200px) {
  .index-page {
    /* Header */
    /* end of header */
    /* Small Features */
    /* end of small features */
    /* Description 1 */
    /* end of description 1 */
    /* Dscription 1 Details Lightbox */
    /* end of description 1 details lightbox */
    /* Description 2 */
    /* end of description 2 */
    /* Features */
    /* end of features */
    /* Screenshots */
    /* end of screenshots */
    /* Testimonials */
    /* end of testimonials */
    /* Statistics */
    /* end of statistics */
    /* Download */
    /* end of download */
    /* Footer */
    /* end of footer */
    /* Extra Pages */
    /* end of extra pages */
  }
  .index-page .header .image-container {
    width: 58.0625rem;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .index-page .header .deco-white-circle-1 {
    top: 14rem;
    left: -27rem;
    width: 40rem;
    height: 40rem;
  }
  .index-page .header .deco-white-circle-2 {
    right: -15rem;
    width: 25.5rem;
    height: 25.5rem;
  }
  .index-page .header .deco-blue-circle {
    top: 29rem;
    left: 10rem;
    width: 6rem;
    height: 6rem;
  }
  .index-page .header .deco-yellow-circle {
    top: 13.5rem;
    right: 8%;
    width: 2.5rem;
    height: 2.5rem;
  }
  .index-page .header .deco-green-diamond {
    top: 7rem;
    left: 18%;
    width: 1.25rem;
    height: 1.25rem;
  }
  .index-page .cards-1 .card:first-of-type {
    margin-left: 0;
  }
  .index-page .cards-1 .card:last-of-type {
    margin-right: 0;
  }
  .index-page .basic-1 .image-container {
    margin-right: 2.5rem;
    width: 31.0625rem;
    height: 36.625rem;
  }
  .index-page .basic-1 .text-container {
    margin-top: 5rem;
    margin-left: 2.5rem;
  }
  .index-page .lightbox-basic .image-container {
    margin-right: 1.5rem;
  }
  .index-page .tabs .image-container {
    margin-left: 2.5rem;
    width: 31.0625rem;
    height: 36.625rem;
  }
  .index-page .tabs .tabs-container {
    margin-top: 6rem;
    margin-right: 2.5rem;
  }
  .index-page .basic-2 .list-unstyled {
    margin-top: 4.5rem;
  }
  .index-page .basic-2 .li-space-lg li {
    margin-bottom: 2.875rem;
  }
  .index-page .basic-2 .first .media-body {
    max-width: 16.375rem;
    margin-right: 6.75rem;
    margin-left: auto;
  }
  .index-page .basic-2 img {
    width: 18.75rem;
    height: 39.8125rem;
  }
  .index-page .slider .swiper-container {
    width: 100%;
  }
  .index-page .slider .swiper-button-prev {
    left: -2.25rem;
    width: 1.375rem;
    background-size: 1.375rem 2.125rem;
  }
  .index-page .slider .swiper-button-next {
    right: -2.25rem;
    width: 1.375rem;
    background-size: 1.375rem 2.125rem;
  }
  .index-page .cards-2 .card {
    width: 21rem;
    margin-right: 1.375rem;
    margin-left: 1.375rem;
  }
  .index-page .cards-2 .image-container img {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
  .index-page .counter #counter .cell {
    margin-right: 2.375rem;
    margin-left: 2.375rem;
  }
  .index-page .basic-3 .image-container {
    width: 57.8125rem;
    height: 36.0625rem;
    margin-right: auto;
    margin-left: auto;
  }
  .index-page .basic-3 .deco-white-circle-1 {
    top: 20rem;
    left: -18rem;
    width: 30rem;
    height: 30rem;
  }
  .index-page .basic-3 .deco-white-circle-2 {
    top: 3rem;
    right: -20rem;
    width: 30rem;
    height: 30rem;
  }
  .index-page .basic-3 .deco-blue-circle {
    top: 17rem;
    right: 7rem;
    width: 6rem;
    height: 6rem;
  }
  .index-page .basic-3 .deco-yellow-circle {
    top: 33rem;
    left: 10rem;
    width: 3.5rem;
    height: 3.5rem;
  }
  .index-page .basic-3 .deco-green-diamond {
    top: 5rem;
    right: 25%;
    width: 1.5rem;
    height: 1.5rem;
  }
  .index-page .footer .footer-col.first {
    display: inline-block;
    width: 14rem;
    margin-right: 2.5rem;
  }
  .index-page .footer .footer-col.second {
    width: 14rem;
    margin-right: 2.25rem;
  }
  .index-page .footer .footer-col.third,
  .index-page .footer .footer-col.fourth {
    width: 8.5rem;
    margin-right: 3.25rem;
  }
  .index-page .footer .fa-stack:last-of-type {
    margin-right: 0;
  }
  .index-page .footer .footer-col.fifth {
    width: 12rem;
    margin-right: 0;
  }
  .index-page .ex-header h1 {
    width: 60%;
    margin-right: auto;
    margin-left: auto;
  }
  .index-page .ex-basic-2 .form-container {
    margin-left: 1.75rem;
  }
  .index-page .ex-basic-2 .image-container-small {
    margin-left: 1.75rem;
  }
}
/* end of min-width 1200px */
@media (max-width: 992px) {
  .login-btn {
    display: none;
  }
}
@media (max-width: 640px) {
  .basic-2 .li-space-lg.first,
  .basic-2 .li-space-lg.last {
    padding-left: 1rem;
  }
  #features {
    padding-bottom: 1rem;
    padding-top: 5rem;
  }
  .index-page .footer {
    padding-top: 4rem;
  }
}/*# sourceMappingURL=style.css.map */