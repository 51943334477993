body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --main-color: #0079bf;
  --button-color: var(--main-color);
  --noImg-color: rgb(151, 151, 151);
  --color: #0145a2;
  --bg: #f2f6fd;
}



.nav-link {
  color: #000;
  /* Change color to your desired active color */
}

.nav-link.active {
  color: #4084a1;
  border-bottom: 2px solid #4084a1;
}