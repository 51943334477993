.leavenote-wrapper {
  position: relative;
  z-index: 0;
}

.upper-dataTbl {
  /* border: 1px solid red !important; */
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid #555; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.upper-btn {
  display: flex;
  float: right;
}

.btn-addlnote {
  display: flex;
  justify-content: right;
}

.leave-status {
  padding: 4px 8px;
}
