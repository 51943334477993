.confirm-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
}

.confirm-leave-popup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 32vw;
  height: 28vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.confirm-leave-body {
  /* border: 1px solid red; */
  height: 8vh;
}

.confirm-footer {
  position: static;
  bottom: 0 !important;
  z-index: 30;
  display: flex;
  float: right;
  padding: 0.5rem;
  margin-right: 0.8rem;
  /* border: 1px solid red; */
}

@media screen and (max-width: 650px) {
  .confirm-popup-inner,
  .confirm-leave-popup-inner {
    width: 80vw !important;
    /* left: 22% !important; */
  }
}

.popUpDelTitle {
  padding-left: 10px;
  font-size: 15px;
}

.delText {
  /* padding-top: 10px; */
  font-size: 14px;
}

.confirm-reverse-popup {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  min-height: 100%;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid green; */
}
