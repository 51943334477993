.attpopup-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
}

.attpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 40%;
  height: 45vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.attpopupBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  /* height: 28vh !important; */
  overflow-y: auto;
}

.disDate ._2xcMq {
  display: none !important;
}
.disDate ._1oYOS {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

.attpopupFooter {
  /* border: 1px solid red; */
  position: absolute;
  z-index: 5000;
  bottom: 5px;
  right: 1px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 900px) {
  .attpopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .attpopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
  .from-date {
    margin-bottom: 10px;
  }
}
