/* ========Navbar css======= */
.mainNav {
  transition: 0.1s;
  /* border: 1px solid green;  */
  position: sticky;
  top: 0;
  z-index: 500;
  margin-left: 250px;
}
.pushMainNav {
  margin-left: 0px !important;
}

.topnav {
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 7px 0px 21px 5px;
  width: 100%;
  background-color: var(--main-color);
}

.topnav .nav-left {
  /* border: 1px solid red; */
  display: flex;
}

.topnav .nav-right {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
}

.nav-user-details {
  /* border: 1px solid green; */
  display: flex;
  padding: 0 10px 0 15px;
}

.nav-user-profile {
  width: 48px;
  height: 48px;
  border-radius: 25px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.nav-user-info {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 15px;
}

.nav-icons,
.nav-search-icons,
.nav-power-icons {
  margin-top: 15px;
  margin-right: 25px;
  cursor: pointer;
}

.nav-power-icons {
  margin-top: 0 !important;
}

.nav-menu-icons {
  margin-top: 15px;
  cursor: pointer;
}

.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  box-shadow: none;
}

.sec-logout {
  border: 2px solid #e1e2e3;
  display: flex;
  padding: 10px 40px;
}

.btn-logout {
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  border: none;
  background-color: white;
  font-weight: 650;
  color: #727399;
}

@media screen and (max-width: 795px) {
  .nav-icons {
    display: none;
  }
  .mainNav {
    transition: 0.1s;
    margin-left: 0px;
  }
  .pushMain {
    margin-left: 0px !important;
  }
}

.nav {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: #fff;
}
.nav .logo {
  padding-left: 3.5rem;
}
/* .large-nav {
  border-left: 1px solid #d9d9d9;
  margin-left: 1rem;
} */
.nav h3 {
  font-weight: bold;
  margin: 0;
  font-size: 18px;
}
.nav h3 span {
  color: #0049ae;
}
.nav p {
  margin: 0;
  font-size: 12px;
}
.nav .address {
  font-size: 13px;
}
.nav span {
  color: #000;
  margin-right: 1rem;
}
.nav .notify {
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
}
.nav .notify:hover svg {
  animation: notify 0.5s;
}
/* .nav .notify:hover .drop-box {
  top: 43.325px !important;
  opacity: 1;
  visibility: visible;
  transition: top 1s ease-in-out 0.5s;
} */

@keyframes notify {
  0% {
    transform: rotate(-45deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.nav .notify .uk-badge {
  background: #0049ae;
  position: absolute;
  top: -50%;
  left: 15px;
}
.nav .notify .drop-box {
  padding: 0;
  /* opacity: 0;
  visibility: hidden;
  top: 100px !important; */
  transition: all 0.3s ease-in-out 0.1s;
  box-shadow: -5px -6px 15px rgba(0, 0, 0, 0.08),
    5px 6px 15px rgba(0, 0, 0, 0.08);
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  /* padding-right: 3rem; */
  /* -ms-overflow-style: none; */
}

.nav .notify .drop-box::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav .notify .drop-box:hover::-webkit-scrollbar {
  display: block !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.nav .notify .drop-box::-webkit-scrollbar-track {
  background-color: transparent;
}

.nav .notify .drop-box::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 1rem;
  width: 40px;
}

.nav .notify .drop-box::before {
  content: "";
  position: absolute;
  display: block;
  top: -11px;
  left: 5px;
  border: 6px solid transparent;
  border-bottom-width: 5px;
  border-bottom-color: #0049ae;
}

.nav .notify .drop-box h5 {
  background: #0049ae;
  color: #fff;
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #fff;
  margin: 0;
  position: sticky;
  top: 0;
  /* position: fixed;
  width: inherit; */
}
.nav .notify .drop-box .notify__wrapper {
  background: #e2f0ff;
  /* padding: 0.5rem; */
  border-bottom: 1px solid #fff;
  transition: all 0.3s ease-in-out;
  /* margin-top: 2.3rem; */
}
.nav .notify .drop-box .notify__wrapper .notify-wrap {
  padding: 0.5rem;
}
.nav .notify .drop-box .notify__wrapper .notify-wrap:hover {
  background: #0049ae;
}
.nav .notify .drop-box .notify__wrapper .notify-wrap:hover .notify-title,
.nav .notify .drop-box .notify__wrapper .notify-wrap:hover .notify-desc {
  color: #fff !important;
}
.nav .notify .drop-box .notify__wrapper .notify-img {
  object-fit: cover;
  height: 50px;
  width: 50px;
  background-color: rgb(151, 151, 151);
  border-radius: 50%;
}
.nav .notify .drop-box .notify__wrapper .notify-title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  transition: all 0.3s ease-in-out;
  text-align: left;
}
.nav .notify .drop-box .notify__wrapper .notify-desc {
  font-size: 12px;
  color: #555;
  transition: all 0.3s ease-in-out;
  width: fit-content;
}
.nav .notify .drop-box .notify__footer {
  background: #0049ae;
  padding: 0.7rem 1rem;
  transition: all 0.3s ease-in-out;
  position: sticky;
  bottom: -1px;
  border-top: 1px solid #fff;
}
.nav .notify .drop-box .notify__footer:hover p {
  letter-spacing: 1px;
}
.nav .notify .drop-box .notify__footer:hover {
  background: #093471;
}
.nav .notify .drop-box .notify__footer:hover p {
  letter-spacing: 1px;
}
.nav .notify .drop-box .notify__footer p {
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.nav .user {
  cursor: pointer;
}
.nav .user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-left: 1rem;
  -o-object-fit: cover;
  object-fit: cover;
}
.nav .chgLang:focus {
  box-shadow: none;
}
.nav .form-check-input {
  width: 2rem;
  margin-left: -2.5em;
  background-image: url(../../images/switch1.svg) !important;
  background-position: left center;
  border-radius: 2em !important;
  transition: background-position 0.15s ease-in-out;
  height: 1rem;
  margin-top: 0.25em;
  margin-right: 0.5rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #004aad;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.nav .form-check-input:checked {
  background-position: right center;
  background-image: url(../../images/switch.svg) !important;
  background-color: #0049ae;
  border-color: #0049ae;
}

.dropLogomenu {
  background: white;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 45;
  margin-top: 5px;
  border: 1px solid #cccccc;
  box-shadow: 0 0px 1px rgb(0 0 0 / 18%);
  background-clip: padding-box;
}

.dropLogomenu ul {
  padding: 10px 40px;

  display: inline-block;
  margin: 0;
}
.dropLogomenu ul li {
  display: block;
}

.dropLogomenu ul li a:hover {
  color: var(--textBlack);
}

.dropLogomenu a {
  text-decoration: none;
  display: block;
}

#mob-nav {
  position: absolute;
  right: 30px;
  padding: 1rem 2rem;
  z-index: 1000;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
  border-radius: 9px;
  top: 60px !important;
  width: 50%;
  left: auto !important;
  background: #f8f8ff;
}

img:not([src]) {
  visibility: visible;
}

.logoff {
  margin-right: 0 !important;
}

.no-notification {
  background: #fff;
}
