.sidenav {
  height: 200vh; /* 100% Full-height */
  width: 0;
  /* width: 100vw;  */
  position: fixed; /* Stay in place */
  z-index: 550; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.1s;
  backdrop-filter: blur(1px);
}

.fullNav {
  width: 100vw !important;
}

.halfNav {
  width: 250px !important;
  background-color: #f5f8fa;
}
/* .pushNav {
  width: 100vw !important;
} */

.side-container {
  width: 250px !important;
}

.title-section {
  width: 250px;
  padding: 1px 5px 2px 25px;
  z-index: 2 !important;
  background-color: var(--main-color);
  display: flex;
  margin-top: -3.8em;
}

.title-section .title-logo {
  /* border:1px solid red; */
  width: 45px;
  height: 45px;
  border-radius: 25px;
  margin-top: 9px;
  margin-bottom: 5px;
}

.title-section .title-name {
  padding: 18px 5px 8px 5px;
  /* border: 1px solid red; */
  font-size: 16px;
  /* font-weight: 700; */
  color: white;
}

.sidenav-close-icons {
  color: white;
  margin-top: 21px;
  margin-left: 37px;
  cursor: pointer;
}

.user-details {
  /* border: 1px solid red; */
  padding-top: 1em;
  display: flex;
  position: sticky;
  padding: 8px 5px 8px 25px;
  color: #727399;
  background-color: #f5f8fa;
}

.img-container {
  /* border: 1px solid red; */
  width: 75px;
  height: 55px;
  border-radius: 50%;
}
.user-profile {
  border: 1px solid red;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 50%;
  border: none;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  background-color: rgb(151, 151, 151);
}

.user-info {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  padding: 5px 0px 5px 15px;
  width: 100%;
}

span.info-designation {
  font-size: 13px;
  text-align: left;
}

span.info-name {
  font-size: 13px;
  padding-top: 5px;
}

.menu-list {
  /* border: 1px solid red; */
  /* padding: 20px 0px 10px 25px; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  list-style: none;
  color: #727399;
  cursor: pointer;
}

.sidenav::-webkit-scrollbar {
  display: none;
}

.sidenav-menus::-webkit-scrollbar {
  display: none;
}

.sidenav-menus {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* CSS for prosidebar **/

.sidenav-menus {
  /* border: 1px solid red; */
  position: relative;
  /* height: 76vh; */
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.pro-sidebar-layout {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.pro-sidebar-layout::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.pro-sidebar > .pro-sidebar-inner {
  background: #004aad !important;
}
.pro-sidebar {
  color: #fff !important;
  height: 100% !important;
  width: 250px !important;
  min-width: 250px !important;
  text-align: left !important;
  transition: width, left, right, 0.3s !important;
  position: relative;
  /* z-index: -1; */
}
span.pro-item-content {
  color: #fff !important;
}
.pro-sidebar .pro-menu {
  height: calc(100vh - 4rem);
  padding-top: 0 !important;
  margin-top: 0.8rem;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #fff !important;
  font-size: 12px;
}
/* .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  background: red;
} */

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background: #004aad !important;
  font-size: 12px !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* pro sidebar icons */
/* .pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #004aad !important;
} */

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  margin-right: 11px !important;
  font-size: 12px !important;
  width: 35px;
  /* min-width: 35px; */
  height: 35px;
  /* line-height: 35px; */
  text-align: center;
  display: inline-block;
  margin-left: -9px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 12px !important;
  font-weight: 700;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 40px !important;
}

/* @media screen and (max-width: 795px) {
  .main {
    transition: 0.1s;
    margin-left: 0px;
  }
  .pushMain {
    margin-left: 0px !important;
  }
} */

.side-menu {
  width: 250px !important;
}
.side__panel {
  width: 57px;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 4rem;
  z-index: 999;
  margin-top: 0.2rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}
.side__panel::-webkit-scrollbar {
  display: none;
}
.side__panel {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.side__panel:hover {
  width: 250px;
}
/* .active {
  background: #022a63;
} */
