.departmentpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 40%;
  height: 35vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}
.branchpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 40%;
  height: 40vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.departmentpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 53vh;
  overflow-y: auto;
}
.branchpopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 26vh;
  overflow-y: auto;
}

.assigncommissionpopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50%;
  height: 29vh;
  top: 14vh;
  left: 22%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
}

@media screen and (max-width: 900px) {
  .departmentpopup-inner,
  .branchpopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .departmentpopup-inner,
  .branchpopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}
