.elite-job-login {
  text-align: center;
  padding: 30px 10px;
}

.elitejoblogo img {
  width: 80px;
}
.elite-job-login h2 {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0;
}
.elite-job-login p {
  font-size: 14px;
  margin: 0;
  margin-bottom: 15px;
}

.css-6j8wv5-Input{
  height: 30px;
  background-color: #efefef7d !important;
  border: none;
  border-radius: 3px !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 13px !important;
  margin: 0 !important;
}
.css-qc6sy-singleValue,.css-14el2xx-placeholder {
	position: relative;
  padding: 0 5px;
}
