.side__content .head p {
  margin-top: 1rem;
}
.side__content .box {
  padding: 1rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border-radius: 0.375rem;
  background: #fff;
}
.side__content .dashNotify .notifyWrapper {
  border-bottom: 1px solid #d9d9d9;
}
.side__content .dashNotify .notifyWrapper:last-child {
  border-bottom: none;
}
.side__content .dashAtt:nth-child(2),
.side__content .dashAtt:last-child {
  padding-left: 1rem;
}
.side__content .dashStat .box {
  height: 316px;
  overflow: auto;
  padding: 0 1rem 1rem 1rem;
}
.side__content .dashInfo .box {
  min-height: 400px;
}
.side__content .dashStat .box h4 {
  padding-top: 1rem;
}

.side__content .profile-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side__content .box p {
  font-size: 18px;
  margin-bottom: 0;
}
.side__content .box h5 {
  font-size: 14px;
  font-weight: 600;
}
.side__content .box .user-icon {
  /* border: 1px solid #d9d9d9; */
  border-radius: 9px;
  padding: 0.5rem;
  margin-right: 1rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.side__content .box .org {
  border-bottom: 1px solid #d9d9d9;
}
.side__content .box .org:last-child {
  border-bottom: none;
}

.side__content .box h4 {
  margin-bottom: 0;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 0.7rem;
  font-size: 14px;
  position: sticky;
  top: 0;
  background: #fff;
}

.side__content .box .wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 9px;
  padding: 0.5rem;
}

.side__content .box .wrapper h5 {
  font-size: 16px;
  text-align: left;
}

.side__content .box .wrapper p {
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}

.side__content .box .wrapper svg {
  font-size: 25px;
  display: block;
  text-align: left;
}

.side__content .box .birthday h5 {
  font-size: 14px;
  margin: 0.5rem 0;
  text-align: left;
}
.side__content .box .attendance {
  height: 300px;
  overflow: auto;
}
.side__content .box .sticky-search {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
}
/* .side__content .box .attendance tbody tr td {
  min-width: 150px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
} */
/* .side__content .box .attendance thead tr td {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
} */
/* .side__content .box .attendance tbody tr td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.side__content .box .attendance thead tr td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
} */

.side__content .box .attendance .sticky-head {
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
}

.side__content .box .attendance h5 {
  font-size: 14px;
  margin: 0.5rem 0;
  text-align: left;
}
.side__content .box .birthday p {
  font-size: 10px;
  margin: 0;
  color: #555;
}
.side__content .box .holiday {
  /* border: 1px solid #d9d9d9; */
  /* border-radius: 9px; */
  margin-top: 1rem;
}
.side__content .box .holiday h5 {
  margin: 0.1rem 0;
  /* font-weight: bold; */
  font-size: 13px;
  text-align: center;
}
.side__content .box .holiday h3 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  color: #000;
}
.side__content .box .holiday .date {
  padding: 0.5rem 1rem;
  border-right: 1px solid #d9d9d9;
  text-align: center;
}
.side__content .box .holiday .content {
  padding: 0 1rem;
}
.side__content .box .holiday span {
  margin: 0;
  font-size: 13px;
}

.side__content .dashStat .box::-webkit-scrollbar,
.side__content .box .attendance::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.side__content .dashStat .box:hover::-webkit-scrollbar,
.side__content .box .attendance:hover::-webkit-scrollbar {
  display: block !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.side__content .dashStat .box::-webkit-scrollbar-track,
.side__content .box .attendance::-webkit-scrollbar-track {
  background-color: transparent;
}

.side__content .dashStat .box::-webkit-scrollbar-thumb,
.side__content .box .attendance::-webkit-scrollbar-thumb {
  background-color: #a6a6a6;
  border-radius: 1rem;
  width: 40px;
}
