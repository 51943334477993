.notification-wrapper {
  /* border: 1px solid green; */
  overflow: hidden;
  /* position: relative; */
}

.notif-body {
  /* border: 1px solid red; */
  position: relative;
  height: 73vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.notification {
  /* border: 1px solid red; */
  padding: 10px 0px;
  margin-bottom: 10px;
  border: 1px solid #e1e2e3;
}

.notify-header {
  /* border: 1px solid red; */
  position: relative;
  height: 60px;
}
.sec-image {
  /* border: 1px solid red; */
  margin-left: 20px;
  position: absolute;
  height: 60px;
  width: 60px;
  display: flex;
  padding: 5px;
}

.creator-img {
  /* border: 1px solid red; */
  object-fit: cover;
  border-radius: 25px;
}

.sec-creator {
  position: absolute;
  left: 60px;
  /* border: 1px solid red; */
  margin-left: 3%;
  padding: 7px 10px;
}

.notify-body {
  /* border: 1px solid red; */
  position: relative;
  height: 150px;
  width: 100%;
}
.sec-notify {
  position: absolute;
  /* border: 1px solid red; */
  margin-left: 20px;
  height: 100%;
  width: 50%;
}

.sec-notify-image {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
  position: absolute;
  left: 65%;
  padding: 5px 0px;
  margin-right: auto;
}

.notification-img {
  /* border: 1px solid green; */
  object-fit: cover;
  height: 140px;
  width: 100%;
}

.notify-btn {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;
}

.nobody-hr {
  padding: 1px;
  margin-left: 10px;
  margin-top: 10px;
  width: 98%;
  margin-bottom: 5px;
}

@media screen and (max-width: 550px) {
  .notify-body {
    height: 200px !important;
  }
  .sec-notify {
    width: 97% !important;
    height: 50px;
  }
  .sec-notify-image {
    top: 55px;
    left: 20px;
    width: 96% !important;
    height: 150px !important;
  }
  .notification-img {
    height: 140px !important;
  }
  .notify-btn {
    display: flex !important;
    width: 100% !important;
    justify-content: center;
  }
}

.addcspopup-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  display: none;
}

.addcspopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50%;
  height: 70vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  display: none;
  border-radius: 6px;
}

.addcspopupBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 60vh;
  overflow-y: auto;
  padding-bottom: 2rem;
}

.addcspopupFooter {
  /* border: 1px solid red; */
  position: fixed;
  z-index: 5000;
  bottom: 0;
  right: 1px;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  width: 100%;
  padding: 0.3rem 0;
}

@media screen and (max-width: 900px) {
  .addcspopup-inner {
    width: 70% !important;
    /* left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .addcspopup-inner {
    width: 90% !important;
    /* left: 5%; */
  }
}

span#basic-addon3 {
  font-size: 13px;
  padding: 0 5px;
}

.sec-addIcon {
  /* border: 1px solid red; */
  height: 53.5px;
  display: flex;
  padding: 0px;
  padding-top: 26px !important;
  margin-left: -8px;
}

.Plus-icon {
  border: 1px solid var(--button-color);
  height: 24px;
  cursor: pointer;
  opacity: 0.6;
  padding: 2px;
}

.Plus-icon:hover {
  opacity: 1;
}

.BoxUpload {
  display: grid;
  /* margin-top: 10px; */
  place-items: center;
  border: 1px solid #aab9d3;
  /* padding: 36px 48px; */
  position: relative;
  height: 150px;
  width: 28%;
  background: #fbfbff;
  border-radius: 5px;
}
.BoxUpload > .image-upload {
  display: flex;
  flex-wrap: wrap;
}
.image-upload > label {
  cursor: pointer;
}
.image-upload > label :hover {
  opacity: 0.8;
}
.image-upload > input {
  display: none;
}

.ImagePreview {
  position: relative;
}
/* cursor: pointer; */
#uploaded-image {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.close-icon {
  background: #000;
  border-radius: 5px;
  opacity: 0.7;
  position: absolute;
  z-index: 10;
  right: 4px;
  top: 5px;
  cursor: pointer;
  z-index: 0;
}
.close-icon:hover {
  opacity: 1;
}

.imgPrv-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  z-index: 100000;
}

/* @media (min-width: 1367px) {
  .imgPrv-popup {
    right: -43px;
    bottom: -99px;
  }
}

@media (min-width: 1600px) {
  .imgPrv-popup {
    right: -43px;
    bottom: -189px;
  }
} */

.imgPrv-popup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50vw;
  height: 80vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.ImgPreview {
  /* border: 1px solid red; */
  position: relative;
  /* width: 150px; */
  height: 100%;
  width: 100%;
  /* margin: 10px; */
  display: flex;
}

#prv-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.imgPrv-close-icon {
  position: absolute;
  color: white;
  background-color: black;
  /* border: 1px solid red; */
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 5;
  opacity: 0.7;
}

.imgPrv-close-icon:hover {
  opacity: 1;
}

.popover-container {
  /* border: 1px solid red; */
  position: absolute;
  right: 15px;
  top: 50px;
  z-index: 6000;
  padding: 5px;
  font-size: 13px;
  width: 25vw;
  background-color: #e1e5ee;
  height: fit-content;
  border-radius: 5px;
}

.popover-close {
  display: flex;
  justify-content: flex-end;
}

.popover-inner {
  padding: 10px;
}

@media (max-width: 860px) {
  .popover-container {
    width: 30vh;
  }
}
