.nav_bar {
  border-radius: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
  border-radius: 0.375rem;
  background: #fff;
  border: 0;
  margin: 0;
  /* margin-bottom: 0.5rem; */
}
.btn-home {
  background: var(--bg);
  color: var(--color);
  font-weight: bold;
  padding: 0 1rem;
  border-radius: 3px;
  font-size: 12px;
  border: 1px solid transparent;
  line-height: 35px;
  transition: all 0.3s ease-in-out;
}
.btn-home:hover {
  color: var(--bg);
  background: var(--color);
}
