.confirm-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
}

.confirm-popup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 35vw;
  height: 25vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.confirm-body {
  /* border: 1px solid red; */
  height: 10vh;
}

.confirm-footer {
  position: absolute;
  z-index: 30;
  bottom: 10px;
  right: 5px;
  /* border: 1px solid red; */
}

@media screen and (max-width: 650px) {
  .confirm-popup-inner {
    width: 80vw !important;
    /* left: 17% !important; */
  }
}
