.leavenotepopup-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  display: none;
}

.leavenotepopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 40%;
  height: 70vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: none;
}

.lnotepopUpBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 57vh;
  overflow-y: scroll;
  /* padding-bottom: 3rem; */
}

.ln-popUpFooter {
  /* border: 1px solid red; */
  position: absolute;
  z-index: 5000;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* background: #fff; */
  background: #fbfbfb;
  padding: 0.3rem 0;
}

@media screen and (max-width: 900px) {
  .leavenotepopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .leavenotepopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
  .from-date {
    margin-bottom: 10px;
  }
}

/* CSS for calender */
._2xcMq {
  top: -2px !important;
  max-width: 300px;
  width: 100%;
  position: absolute;
  background-color: #fff;
  /* z-index: 1000 !important; */
  color: #000;
  box-shadow: none !important;
  font-size: 12px !important;
  line-height: 1 !important;
  /* border: 1px solid #e1e2e3 !important; */
  display: flex !important;
  justify-content: center !important;
}

p._3fmCl._1Pw8c._bBYU7 {
  display: none !important;
}

p._3fmCl._1ydFT._bBYU7 {
  display: none !important;
}

select._2aNF9 {
  font-size: 13px;
  margin-left: 3px;
  border: none !important;
}

._1Op28._1mUuW {
  display: flex !important;
  justify-content: center;
}

span._249_g._3zVi3._FsLy_._1ImcB {
  border-radius: inherit;
}
