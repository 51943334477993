/* .login-container {
  width: 100%;
  max-width: 900px;
  border: 1px solid #e1e2e3;
  border-radius: 0.2rem;
  box-shadow: 0 0 0 #000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.login-form-1 {
  padding-top: 70px;
}

.login-form-1 h5 {
  color: #356aae;
}

.login-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #356aae;
  width: 100%;
  border-radius: 0rem;
  padding: 1.5%;
  border: none;
  margin-top: 7px;
  cursor: pointer;
}

.login-form-2 {
  background: #356aae;
  color: #fff;
}
.login-form-2 h3 {
  color: #fff;
  padding-top: 70px;
}
.login-form-2 h6 {
  color: #fff;
  padding-top: 45px;
}
.login-form-2 p {
  padding-top: 40px;
}
.owl-theme .owl-nav {
  display: none;
}
.owl-theme .owl-dots .owl-dot {
  margin-top: 15px;
  margin-left: -10px;
}
.owl-carousel .owl-item img {
  display: initial;
  width: 90%;
}
button.owl-dot.active {
  background: white !important;
}

button.owl-dot {
  height: 10px !important;
  font-size: 13px !important;
  background: #d6d6d6 !important;
  color: black !important;
  margin: 5px !important;
  width: 10px;
  border-radius: 50%;
} */

/* .login-container {
  width: 100%;
  min-height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.errormsg {
  font-size: 13px;
  color: #ff4949;
  margin: 0 !important;
}

.border3,
.border1,
.border2 {
  border: 1px solid #ff4949 !important;
}

.login__page {
  margin-top: 3rem;
}

.login__page .login__img .txt h1 {
  font-weight: bold;
  letter-spacing: 0.04em;
  position: relative;
  display: inline-block;
  z-index: 1;
  font-size: 50px;
}
.login__page .login__img .txt h1 span {
  color: #0145a2;
}
.login__page .login__img .txt h1::before {
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: rgba(0, 73, 174, 0.5);
  filter: blur(40px);
  top: 0;
  left: 70%;
  transform: translateX(-50%);
  z-index: -1;
}
.login__page .login__img .slider-img .swiper-slide {
  padding: 2rem 0;
}
.login__page .login__img .slider-img .swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 15px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 5px)
  );
  border-radius: 0;
  background: var(--swiper-pagination-bullet-inactive-color, #777);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
}
.login__page .login__img .slider-img .swiper-pagination-bullet-active {
  opacity: 1;
  background: #0145a2;
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 30px)
  );
}
.login__page .login__form {
  box-shadow: 6px 5px 15px rgba(0, 0, 0, 0.08),
    -6px -5px 15px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  padding: 2rem;

  position: relative;
  background: #fff;
}
.login__page .login__form h3 {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 2rem 0;
  text-transform: initial;
}
/* .login__page .login__form h3 {
  color: #0145a2;
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 0;
} */
.login__page .login__form h3 span {
  color: #000;
  font-size: 16px;
}
.login__page .login__form h5 {
  margin: 0;
  font-size: 16px;
}
.login__page .login__form form .uk-input {
  width: 100%;
  padding: 1rem;
  padding-right: 2rem;
  background-color: #efefef7d !important;
  border: none;
  border-radius: 3px;
  color: #000;
}
/* .login__page .login__form form .uk-input {
  width: 100%;
  padding: 1.5rem 3rem 1.5rem 3rem;
  background: #e6edf7;
  border-bottom: 3px solid #0145a2;
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  border-radius: 9px 9px 0 0;
  color: #000;
} */
/* .login__page .login__form form .uk-input:focus {
  outline: 0;
  background-color: #fff;
  color: #000;
  border-color: #0145a2;
} */
.login__page .login__form form ::-moz-placeholder {
  color: #777;
}
.login__page .login__form form :-ms-input-placeholder {
  color: #777;
}
.login__page .login__form form ::placeholder {
  color: #777;
}
.login__page .login__form form .uk-button {
  background: linear-gradient(180deg, #0145a2 0%, #3785f1 100%);
  color: #fff;
  width: 100%;
  font-weight: bold;
  border-radius: 9px;
  margin: 1rem auto 0 auto;
  display: block;
  border: 1px solid transparent;
  /* transition: all 0.5s linear; */
  padding: 0 0.3rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.login__page .login__form form .uk-button:hover {
  background: #fff;
  color: #0145a2;
  border: 1px solid #0145a2;
}

.login__page .login__form form .username-input {
  margin-top: 1rem;
}
.login__page .login__form form .username-input .username-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding-left: 1rem;
  cursor: pointer;
}
.login__page .login__form form .username-input .username-icon:focus-visible {
  outline: none;
}
.login__page .login__form form .password-input {
  margin-top: 1rem;
}
.login__page .login__form form .password-input .password-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  padding-left: 1rem;
  cursor: pointer;
}
.login__page .login__form form .password-input .password-icon:focus-visible {
  outline: none;
}
.login__page .login__form form .password-input .toggle-password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding-right: 1rem;
  cursor: pointer;
}
.login__page .login__form form .password-input .toggle-password:focus-visible {
  outline: none;
}
.login__page .login-form-wrapper {
  position: relative;
}
.login__page .login-form-wrapper::before {
  content: url(../images/blob.png);
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login__page .login__form .bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 6px 5px 15px rgba(0, 0, 0, 0.08),
    -6px -5px 15px rgba(0, 0, 0, 0.08);
}
.login__page .login__form .bg .login-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .login__page .login-form-wrapper,
.login__page .login-img-wrapper {
  padding: 40px !important;
} */

.login__page .login-form-wrapper {
  width: 480px;
  margin: 2rem auto;
  margin-top: 7rem;
}

.copyright {
  display: block;
  text-align: center;
  font-size: 14px;
  padding-bottom: 1rem;
  color: #0145a2 !important;
  position: relative;
  z-index: 1;
}

.copyright a {
  color: #0145a2 !important;
}

.copyright a span {
  color: #a61011;
}

/* for bubbles effect *******************************************************************************/

.bubbles-wrapper {
  position: absolute;
  width: 25%;
  min-height: 200px;
  bottom: 0;
  right: 70px;
}

@media (max-width: 992px) {
  .bubbles-wrapper {
    display: none;
  }
}

.bubbles-wrapper .bubble {
  position: absolute;
}

.bubbles-wrapper .icon-md {
  width: 2rem;
  height: 2rem;
}
.bubbles-wrapper .icon-lg {
  width: 3.25rem;
  height: 3.25rem;
}
.bubbles-wrapper .icon-xl {
  width: 4.5rem;
  height: 4.5rem;
}
.bubbles-wrapper .icon-xxl {
  width: 6.5rem;
  height: 6.5rem;
}

.bubbles-wrapper .icon {
  margin-left: 5px;
  margin-right: 15px;
  padding: 0;
  display: inline-block;
  text-align: center;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-circle img {
  border-radius: 50%;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.bubbles-wrapper img {
  vertical-align: middle;
}

@keyframes bubble-v {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-640px);
  }
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }

  65% {
    transform: translate(0, 10px);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes shake-v {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 20px;
  }
}

.bubbles-wrapper .bubble:nth-child(1) {
  animation: floating 4.8s ease-in-out infinite alternate;
  bottom: 45%;
  left: 22%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(1) {
    animation: bubble-v 15.8s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

/* .bubbles-wrapper .bubble.top-initial:nth-child(1) {
  top: 0
} */

.bubbles-wrapper .bubble:nth-child(2) {
  animation: floating 5.26667s ease-in-out infinite alternate;
  bottom: 45%;
  left: 22%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(2) {
    animation: bubble-v 21.7s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

.bubbles-wrapper .bubble:nth-child(3) {
  animation: floating 6.6s ease-in-out infinite alternate;
  bottom: 40%;
  left: 32%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(3) {
    animation: bubble-v 28.8s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

.bubbles-wrapper .bubble:nth-child(4) {
  animation: floating 5.9s ease-in-out infinite alternate;
  bottom: 51%;
  left: 71%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(4) {
    animation: bubble-v 18.8s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

.bubbles-wrapper .bubble:nth-child(5) {
  animation: floating 6.5s ease-in-out infinite alternate;
  bottom: 65%;
  left: 50%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(5) {
    animation: bubble-v 19.5s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

.bubbles-wrapper .bubble:nth-child(6) {
  animation: floating 7.56667s ease-in-out infinite alternate;
  bottom: 44%;
  left: 5%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(6) {
    animation: bubble-v 22.7s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

.bubbles-wrapper .bubble:nth-child(7) {
  animation: floating 9.16667s ease-in-out infinite alternate;
  bottom: 80%;
  left: 70%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(7) {
    animation: bubble-v 27.5s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

.bubbles-wrapper .bubble:nth-child(8) {
  animation: floating 7.66667s ease-in-out infinite alternate;
  bottom: 55%;
  left: 38%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(8) {
    animation: bubble-v 23s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}

.bubbles-wrapper .bubble:nth-child(9) {
  animation: floating 7.23333s ease-in-out infinite alternate;
  bottom: 40%;
  left: 75%;
}

@media (min-width: 992px) {
  .bubbles-wrapper .bubble:nth-child(9) {
    animation: bubble-v 21s linear infinite,
      shake-v 2s ease-in-out infinite alternate;
  }
}
