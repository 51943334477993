.viewNotifyPopup-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5500;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  min-width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* border: 1px solid red; */
  transform: translate(-50%, -50%);
  display: none;
}

.viewNotifyPopup-inner {
  background-color: white;
  /* max-width: 950px; */
  width: 50%;
  height: 58vh;
  top: 50%;
  left: 50%;
  position: absolute;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  display: none;
}

.viewNotifyPopupBody {
  position: relative;
  width: 100%;
  z-index: 2;
  /* border: 1px solid red; */
  height: 45vh;
  overflow-y: auto;
}

.viewNotifyPopupFooter {
  /* border: 1px solid red; */
  position: absolute;
  z-index: 5000;
  bottom: 5px;
  right: 1px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 900px) {
  .viewNotifyPopup-inner {
    width: 70% !important;
    /* top: 9vh;
    left: 15%; */
  }
}

@media screen and (max-width: 577px) {
  .viewNotifyPopup-inner {
    width: 90% !important;
    /* top: 9vh;
    left: 5%; */
  }
}

.notification {
  /* border: 1px solid red; */
  padding: 10px 0px;
  margin-bottom: 10px;
  border: 1px solid #e1e2e3;
  overflow-x: hidden;
}

.notify-header {
  /* border: 1px solid red; */
  position: relative;
  height: 60px;
}
.sec-image {
  /* border: 1px solid red; */
  margin-left: 20px;
  position: absolute;
  height: 60px;
  width: 60px;
  display: flex;
  padding: 5px;
}

.creator-img {
  /* border: 1px solid red; */
  object-fit: fill;
  border-radius: 25px;
  height: 50px;
  width: 55px;
  background-color: rgb(151, 151, 151);
}

.sec-creator {
  position: absolute;
  left: 75px;
  /* border: 1px solid red; */
  margin-left: 3%;
  padding: 7px 10px;
  width: 75%;
}

.notify-body {
  /* border: 1px solid red; */
  position: relative;
  height: 150px;
  width: 100%;
}
.sec-notify {
  /* position: absolute; */
  /* border: 1px solid red; */
  margin-left: 20px;
  height: 100%;
  width: 55%;
  overflow-y: scroll;
}
.sec-notify::-webkit-scrollbar {
  display: none;
}

.sec-notify {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.sec-notify-image {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  width: 30%;
  height: 100%;
  position: absolute;
  left: 65%;
  padding: 5px 0px;
  margin-right: auto;
}

.notification-img {
  /* border: 1px solid green; */
  object-fit: cover;
  height: 140px;
  width: 100%;
  background-color: rgb(151, 151, 151);
}

.notify-btn {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;
}

.nobody-hr {
  padding: 1px;
  margin-left: 10px;
  margin-top: 10px;
  width: 95%;
  margin-bottom: 5px;
}

@media screen and (max-width: 550px) {
  .notify-body {
    height: 200px !important;
  }
  .sec-creator {
    left: 80px !important;
  }
  .sec-notify {
    position: relative !important;
    width: 97% !important;
    height: fit-content !important;
    padding-right: 10px !important;
    /* margin-bottom: 10px; */
  }
  .sec-notify-image {
    position: relative !important;
    top: 5px;
    left: 20px;
    width: 96% !important;
    height: 150px !important;
  }
  .notification-img {
    height: 140px !important;
    margin: 5px 0 !important;
  }
  .notify-btn {
    display: flex !important;
    width: 100% !important;
    justify-content: center;
  }
}
