#footer {
  color: #444444;
  font-size: 14px;
  background: var(--bg);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  /* background: #fff; */
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h4 {
  font-size: 16px;
  font-weight: bold;
  color: #2d405f;
  position: relative;
  padding-bottom: 4px;
  margin: 0;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Krub", sans-serif;
  color: #4e6fa4;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #2d405f;
  position: relative;
  padding-bottom: 12px;
  margin: 0;
}

#footer .footer-top .footer-links p {
  font-size: 14px;
  margin: 0;
  color: #777777;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: var(--color);
  font-size: 10px;
  line-height: 1;
  margin-right: 0.5rem;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: var(--color);
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  color: #2d405f;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 5px;
  text-align: left;
  border: 1px solid #9da7fc;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: -1px;
  right: -2px;
  bottom: -1px;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #3b4ef8;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #0a22f6;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
  color: #444444;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: var(--bg);
  color: var(--color);
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 5px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: var(--color);
  color: #fff;
  text-decoration: none;
}
