.tab {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 1.5rem;
}
.nav-link:focus,
.nav-link:hover {
  color: #495057;
}
.nav-tabs .nav-link {
  color: #495057;
}
