tr:nth-of-type(odd) {
  background-color: #f8f8ff;
}

.tablecell {
  padding: 0.5rem 1rem !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.tablecell .trial-btn {
  padding: 0;
  height: 18px;
  width: 18px;
  line-height: 18px;
  margin-right: 0.5rem;
}
.tablecell:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.tableHead {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 1rem !important;
  font-weight: bold;
}
.tableHead svg {
  margin-left: 10px;
}
tbody tr {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.tableHeight {
  max-height: 600px;
  overflow: auto;
  position: relative;
}

.tableHeight .fixed-head {
  position: sticky;
  top: -1px;
  z-index: 1;
}
.tableHeight .fixed-foot {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}

.sticky-right {
  position: sticky;
  right: 0;
  top: -1px;
}
.sticky-left {
  position: sticky;
  left: 0;
  top: -1px;
  background: #fff;
}

.reportTable tr:nth-of-type(odd) {
  background-color: #fff;
}
.reportTable .reportTotal {
  background-color: #f8f8ff !important;
}

td {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 1rem !important;
  font-size: 13px;
}
.personalSlip td {
  font-weight: bold;
}
.personalSlip .slipInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
/* .print-table .tableHeight {
  max-height: 372px !important;
} */
.new__table :nth-of-type(odd) {
  background-color: transparent;
}
.new__table .new__table__row {
  background-color: transparent;
}
.new__table .new__table__row td {
  padding: 0 !important;
  border-bottom: none;
  border-top: none;
  border-left: none;
  /* width: 218px; */
}
.new__table tr td:first-child {
  background-color: #fff;
}
.new__table .new__table__row td > div {
  padding: 0.5rem 1rem;
}
.table__date {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: bold;
}
.table__time {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.att__row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  width: max-content;
  min-width: 100%;
}
.new__table .head-top {
  background: #004aad;
}
/* .att__row td:last-child .table__time{
  border-bottom: none;
} */
@media (max-width: 1499px) {
  .att__row {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1399px) {
  .att__row {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1199px) {
  .att__row {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 960px) {
  .att__row {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 640px) {
  .att__row {
    grid-template-columns: repeat(1, 1fr);
  }
}
